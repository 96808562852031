/* eslint-disable no-console */
import axios, { AxiosResponse } from "axios";
import env from "../config/environment";
import { ICartPriceObject, IPixelAdvancedMatchingCustomerData } from "../interfaces";

const { TNS_PLATFORM_API } = env;

const logEventToServer = async (
  eventID: string,
  eventName: string,
  eventTimestamp: number,
  userData: IPixelAdvancedMatchingCustomerData,
  customData: any,
  eventSourceUrl?: string,
  pixelTestEventCode?: string,
  customerOptOut?: boolean,
): Promise<AxiosResponse<any>> => {
  try {
    let eventData: any = {
      eventID,
      eventName,
      userData,
      customData,
    };

    if (eventTimestamp !== undefined) {
      eventData = { ...eventData, eventTimestamp };
    }
    if (eventSourceUrl) {
      eventData = { ...eventData, eventSourceUrl };
    }
    if (customerOptOut) {
      eventData = { ...eventData, customerOptOut };
    }
    if (pixelTestEventCode) {
      eventData = { ...eventData, pixelTestEventCode };
    }

    return axios.post(`${TNS_PLATFORM_API}/api/events/logEvent`, eventData);
  } catch (err) {
    console.error(err.response ? err.response.data : "Something went wrong");
    throw err;
  }
};

const checkoutStarted = async (orderId: string): Promise<void> => {
  try {
    // deactivated - the "create order" event used instead
    return;

    // axios.post(`${TNS_PLATFORM_API}/api/events/checkoutStarted`, { orderId });
    // return;
  } catch (err) {
    console.error(err.response ? err.response.data : "Something went wrong");
    throw Error(err.response.data.message);
  }
};

const transactionLogPaymentPageDisplayed = async (
  orderId: string,
  customerId: string,
  cartId: string,
  currency: string,
  totalPrice: number,
  price?: ICartPriceObject,
): Promise<any> => {
  try {
    const d = {
      orderId,
      customerId,
      cartId,
      price,
      totalPrice,
      currency,
    };

    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/events/transactionLogPaymentPageDisplayed`, d);
    return data;
  } catch (err) {
    console.error(err.response.data);
    return {
      status: "failure",
      message: "Could not log Payment Page view",
    };
  }
};

const transactionLogKlarnaPlaceOrderClicked = async (
  orderId: string,
  customerId: string,
  cartId: string,
  klarnaSourceId: string,
  currency: string,
  totalPrice: number,
): Promise<any> => {
  try {
    const d = {
      orderId,
      customerId,
      cartId,
      klarnaSourceId,
      currency,
      totalPrice,
    };

    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/events/transactionLogKlarnaPlaceOrderClicked`, d);
    return data;
  } catch (err) {
    console.error(err.response.data);
    return {
      status: "failure",
      message: "Could not log Payment Page view",
    };
  }
};

const transactionLogKlarnaRedirectPlaceOrderClicked = async (
  orderId: string,
  customerId: string,
  cartId: string,
  currency: string,
  totalPrice: number,
): Promise<any> => {
  try {
    const d = {
      orderId,
      customerId,
      cartId,
      currency,
      totalPrice,
    };

    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/events/transactionLogKlarnaRedirectPlaceOrderClicked`, d);
    return data;
  } catch (err) {
    console.error(err.response.data);
    return {
      status: "failure",
      message: "Could not log Payment Page view",
    };
  }
};

export default {
  checkoutStarted,
  logEventToServer,
  // Payment
  transactionLogPaymentPageDisplayed,
  transactionLogKlarnaPlaceOrderClicked,
  transactionLogKlarnaRedirectPlaceOrderClicked,
};
