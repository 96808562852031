import React, { useEffect, useState, useRef } from "react";
import Cookies from "universal-cookie";
import { SHA256 } from "sha2";
import { customAlphabet } from "nanoid";
import ReactPixel from "../utils/analytics/ReactFacebookPixel";
import BingPixel from "../utils/analytics/bingAdsPixel";
import {
  FBP_TRACKING_ID,
  facebookContentCategory,
  facebookGroupTitle,
  facebookCatalog,
  facebookContentCategoryForPendant,
  // facebookPendantCatalog,
} from "../resources/facebook";
import { countries } from "../resources/countries";
import stateCodes from "../resources/state_codes";
import eventLogging from "../api/logging";
import { getSourceUrl } from "../utils/utils";
import { IPixelUserData, IPixelAdvancedMatchingCustomerData, ICartItem } from "../interfaces";
// import configApi from "../api/config";
// import queryString from "query-string";
const nanoid = customAlphabet("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", 16);

declare global {
  interface Window {
    pintrk: (action: string, event: string, data?: any) => void;
    // fbq: any;
    // consentLoaded: boolean | undefined;
    // gdprApplies: boolean | undefined;
    // gdprRestricted: boolean | undefined;
    // ccpaApplies: boolean | undefined;
    dataLayer: any[];
  }
}

const countryCodes = Object.keys(countries);

let TrackingDataContext: any; // eslint-disable-line import/no-mutable-exports
const { Provider, Consumer } = (TrackingDataContext = React.createContext({})); // eslint-disable-line no-multi-assign

interface PixelOptions {
  autoConfig: boolean;
  debug: boolean;
}

const pixelOptions: PixelOptions = {
  autoConfig: true,
  debug: false,
};

const TrackingDataProvider = ({ children }): JSX.Element => {
  // let customerEmail: string | null = null;
  // const eventQueue = useRef<any[]>([]);

  // Customer Data
  // const [trackingInitTimeout, setTrackingInitTimeout] = useState<Date>();
  const [orderId, setOrderId] = useState<string | null>(null);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [customerEmail, setCustomerEmail] = useState<string | null>(null);
  const [customerPhone, setCustomerPhone] = useState<string | null>(null);
  const [customerFirstName, setCustomerFirstName] = useState<string | null>(null);
  const [customerLastName, setCustomerLastName] = useState<string | null>(null);
  const [customerCity, setCustomerCity] = useState<string | null>(null);
  const [customerStateCode, setCustomerStateCode] = useState<string | null>(null);
  const [customerZipCode, setCustomerZipCode] = useState<string | null>(null);
  const [customerCountryCode, setCustomerCountryCode] = useState<string | null>(null);
  const [pixelTestEventCode, setPixelTestEventCode] = useState<string | null>("TEST79361");
  const deliveryCategory = "home_delivery";

  /**
   * Other Data:
   */
  //   Click ID: fbc - Do not hash
  //   Browser ID: fbp - Do not hash
  //
  //   Client IP address: client_ip_address - Do not hash
  //   Client user agent: client_user_agent - Do not hash
  //
  //   Subscription ID: subscription_id - Do not hash
  //   Facebook Login ID: fb_login_id - Do not hash
  //   Lead ID: lead_id - Do not hash
  const hashAndTrimStringIfRequested = (hashed: boolean, input?: string | null): string | null => {
    try {
      if (!input || input === null || input === undefined || input === "" || input.trim() === "") {
        return null;
      }

      if (hashed === true) {
        const hashBuffer = SHA256(input.trim());
        if (hashBuffer) {
          const hashString = hashBuffer.toString();
          if (hashString && hashString !== "") {
            return hashString;
          }
        }
      } else {
        return input.trim();
      }
    } catch (error) {
      // Bad string - don't send
    }

    return null;
  };

  /**
   * Helper Function to build the Advanced Matching user data object
   */
  const buildUserData = (): IPixelUserData => {
    const userData: IPixelUserData = {};

    // External ID (Param: "external_id") - Any unique ID from the advertiser, such as loyalty
    // membership ID, user ID, and external cookie ID.
    // a bit silly, but make absolutely sure no weird data passed into External Id:
    if (
      customerId &&
      customerId !== undefined &&
      customerId !== null &&
      customerId !== "" &&
      `${customerId}` !== "undefined" &&
      `${customerId}` !== "null"
    ) {
      // Disabled because FBQ script not passing it through and not used on server side; could be put bacl
      // as EXTERN_ID param for audience matching
      // userData.externalId = `${customerId}`;
      // console.log(`Send FB external_id for tracking event`);
    } else {
      // console.log(`Do not send FB external_id for tracking event`);
    }

    // Email (Param: "em")
    if (customerEmail && customerEmail !== "") {
      userData.email = customerEmail.toLowerCase();
    }

    // Phone (Param: "ph") - Digits only including country code and area code
    if (customerPhone && customerPhone !== "") {
      try {
        const phoneNumberDigits = customerPhone.replace(/[^0-9]/g, "");
        if (phoneNumberDigits && phoneNumberDigits !== "") {
          userData.phone = phoneNumberDigits;
        }
      } catch (error) {
        // ignore
      }
    }

    // First Name (Param: "fn") - Lowercase lettedrs
    if (customerFirstName && customerFirstName !== "") {
      userData.firstName = customerFirstName.toLowerCase();
    }

    // Last Name (Param: "ln") - Lowercase letters
    if (customerLastName && customerLastName !== "") {
      userData.lastName = customerLastName.toLowerCase();
    }

    // City (Param: "ct") - Lowercase with any spaces removed
    if (customerCity && customerCity !== "") {
      userData.city = customerCity.toLowerCase().replace(/ /g, "");
    }

    // State or Province (Param: "st") - Lowercase two-letter state or province code
    if (customerStateCode && customerStateCode !== "" && stateCodes.indexOf(customerStateCode.toUpperCase()) >= 0) {
      userData.stateCode = customerStateCode.toLowerCase();
    }

    // Zip or Postal Code (Param: "zp") - Digits only
    if (customerZipCode && customerZipCode !== "") {
      try {
        const zipCodeDigits = customerZipCode.replace(/[^0-9]/g, "");
        if (zipCodeDigits && zipCodeDigits !== "") {
          userData.zipCode = zipCodeDigits;
        }
      } catch (error) {
        // ignore
      }
    }

    // Country (Param: "country") - Lowercase two-letter country code
    if (customerCountryCode && customerCountryCode !== "" && countryCodes.indexOf(customerCountryCode.substring(0, 2).toUpperCase()) >= 0) {
      userData.countryCode = customerCountryCode.toLowerCase();
    }

    return userData;
  };

  /**
   * Helper Function to build the Advanced Matching user data object
   */
  const buildAdvancedMatchingData = (hashed: boolean): IPixelAdvancedMatchingCustomerData => {
    /**
     * Build Advanced Matching Customer Parameters
     *
     * See: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching/
     */
    const userData = buildUserData();

    const advancedMatchingData: IPixelAdvancedMatchingCustomerData = {};

    const cookies = new Cookies();
    const facebookCookieFbc = cookies.get(`_fbc`);
    if (facebookCookieFbc && facebookCookieFbc !== undefined && facebookCookieFbc !== null && facebookCookieFbc !== "") {
      advancedMatchingData.fbc = facebookCookieFbc;
    }

    const facebookCookieFbp = cookies.get(`_fbp`);
    if (facebookCookieFbp && facebookCookieFbp !== undefined && facebookCookieFbp !== null && facebookCookieFbp !== "") {
      advancedMatchingData.fbp = facebookCookieFbp;
    }

    advancedMatchingData.external_id = userData.externalId;

    // Email (Param: "em")
    advancedMatchingData.em = hashAndTrimStringIfRequested(hashed, userData.email);
    advancedMatchingData.ph = hashAndTrimStringIfRequested(hashed, userData.phone);
    advancedMatchingData.fn = hashAndTrimStringIfRequested(hashed, userData.firstName);
    advancedMatchingData.ln = hashAndTrimStringIfRequested(hashed, userData.lastName);
    advancedMatchingData.ct = hashAndTrimStringIfRequested(hashed, userData.city);
    advancedMatchingData.st = hashAndTrimStringIfRequested(hashed, userData.stateCode);
    advancedMatchingData.zp = hashAndTrimStringIfRequested(hashed, userData.zipCode);
    advancedMatchingData.country = hashAndTrimStringIfRequested(hashed, userData.countryCode);
    // Gender (Param: "ge") - Single lowercase letter, f or m, if unknown, leave blank
    // Birthdate (Param: "db") - Digits only with birth year, month, then day

    return advancedMatchingData;
  };

  // const initPixel = () => {
  //   if (window.gdprApplies === true && window.gdprRestricted === true) {
  //     // console.log(`X: GDPR: [window.gdprApplies: ${window.gdprApplies}][window.gdprRestricted: ${window.gdprRestricted}]`);
  //     // Do not grant consent
  //     return;
  //   }

  //   // Revoke consent before Pixel init (unless we know GDPR not applicable)
  //   if (!window.gdprApplies || window.gdprApplies === true) {
  //     // console.log(`X: GDPR: [window.gdprApplies: ${window.gdprApplies}]`);
  //     ReactPixel.revokeConsent();
  //   }

  //   // Enable with GeoLocation, regardless of Quantcast: if (window.ccpaApplies === true)
  //   ReactPixel.activateLDUWithGeolocation();
  //   // ReactPixel.deactivateLDU();

  //   // Init pixel
  //   // console.log(`X: GDPR: Pixel Init`);
  //   if (window.gdprApplies !== undefined) {
  //     ReactPixel.init(FBP_TRACKING_ID, buildAdvancedMatchingData(true), pixelOptions);
  //   }

  //   if (window.consentLoaded === true) {
  //     // console.log(`X: GDPR: consentLoaded`);
  //     // Initialize FB Pixel
  //     if (!window.gdprApplies || (window.gdprApplies === true && window.gdprRestricted === false)) {
  //       // console.log(`X: GDPR: grantConsent()`);
  //       ReactPixel.grantConsent();
  //       // if events queued
  //       // if (eventQueue.current && eventQueue.current.length >= 0) {
  //       //   for (let i = 0; i < eventQueue.current.length; i += 1) {
  //       //     eventQueue.current[i]();
  //       //   }
  //       // }
  //     } else {
  //       // console.log(`X: GDPR: Do NOT grantConsent()`);
  //     }

  //     return;
  //   }

  //   if (!trackingInitTimeout || new Date() < trackingInitTimeout) {
  //     // Pixel Init Incomplete (maybe waiting on cookies or ?)
  //     // console.log(`X: GDPR: setTimeout()`);
  //     setTimeout(initPixel, 800);
  //   }
  // };

  // useEffect(() => {
  //   initPixel();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   customerId,
  //   customerEmail,
  //   customerPhone,
  //   customerFirstName,
  //   customerLastName,
  //   customerCity,
  //   customerStateCode,
  //   customerZipCode,
  //   customerCountryCode,
  // ]);

  // useEffect(() => {
  //   // Timeout tracking start in 10 seconds
  //   setTrackingInitTimeout(new Date(new Date().getTime() + 10 * 1000));

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const initPixel = () => {
    // Init pixel
    ReactPixel.init(FBP_TRACKING_ID, buildAdvancedMatchingData(true), pixelOptions);
  };

  useEffect(() => {
    initPixel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerId,
    customerEmail,
    customerPhone,
    customerFirstName,
    customerLastName,
    customerCity,
    customerStateCode,
    customerZipCode,
    customerCountryCode,
  ]);

  const sendFbTrackingEvent = (eventName, customData, fbcMinRequired) => {
    const eventID = `${nanoid()}_${new Date().getTime()}`;
    const eventTimestamp = Math.floor(new Date().getTime() / 1000);

    // Ensure Pixel Loaded (with up to date adv matching data)
    initPixel();

    // Get data:
    const advancedMatchingData = buildAdvancedMatchingData(false);

    let trackOk = true;
    if (fbcMinRequired) {
      if (!advancedMatchingData) {
        trackOk = false;
      } else {
        // // no email:
        // // eslint-disable-next-line no-lonely-if
        // if (
        //   !advancedMatchingData.em ||
        //   advancedMatchingData.em === undefined ||
        //   advancedMatchingData.em === null ||
        //   advancedMatchingData.em === ""
        // ) {
        //   trackOk = false;
        // }
        // if (
        //   !advancedMatchingData.fbc ||
        //   advancedMatchingData.fbc === undefined ||
        //   advancedMatchingData.fbc === null ||
        //   advancedMatchingData.fbc === ""
        // ) {
        //   trackOk = false;
        // }
      }
    }

    if (trackOk) {
      console.log(`>>> Facebook Event: ${eventName}: ${eventID} - ${JSON.stringify(advancedMatchingData)}`);
      ReactPixel.track(eventName, customData, { eventID, eventName, eventTimestamp });
      eventLogging.logEventToServer(
        eventID,
        eventName,
        eventTimestamp,
        advancedMatchingData,
        customData,
        getSourceUrl() || "",
        pixelTestEventCode || "",
        // customerOptOut?: boolean,
      );
    }
  };

  const sizeIdIsSquare = (size: string): boolean | null => {
    if (!size) return null;

    const parts = size.toLowerCase().split("x");
    if (parts.length === 2) {
      if (parts[0] === parts[1]) {
        return true;
      }

      return false;
    }

    return false;
  };

  const getFacebookCatalogEntryForAllItems = () => {
    return {
      contentIds: facebookCatalog.map((x) => x.id),
      contentType: "product",
      contentName: facebookGroupTitle,
      contentCategory: facebookContentCategory,
    };
  };

  const getFacebookCatalogEntryForItem = (item: ICartItem) => {
    if (item.isPendant) {
      const pendantCatalogIds = [
        "TNS-US-PEN-S",
        "TNS-US-PEN-C",
        "TNS-US-PEN-G",
        "TNS-US-PEN-SCG",
        "TNS-US-PEN-SC",
        "TNS-US-PEN-T",
        "TNS-US-PEN-TD",
        "TNS-US-PEN-M",
        "TNS-US-PEN-MS",
        "TNS-US-PEN-SC",
        "TNS-US-PEN-MC",
        "TNS-US-PEN-TM",
        "TNS-US-PEN-CS",
        "TNS-US-PEN-SMC",
        "TNS-US-PEN-MSDT",
        "TNS-US-PEN-MST",
        "TNS-US-PEN-TC",
        "TNS-US-PEN-CD",
        "TNS-US-PEN-TMD",
      ];

      const randCatalogId = pendantCatalogIds[Math.floor(Math.random() * pendantCatalogIds.length)];
      return {
        contentId: randCatalogId,
        contentType: "product",
        contentName: facebookGroupTitle, // catalog[0].title,
        contentCategory: facebookContentCategoryForPendant,
        contentGroupId: "TNS_PENDANT_V1",
      };

      // const pendantCatalog = facebookPendantCatalog.filter(
      //   (c) =>
      //     c.stars === item.design.customProps.stars &&
      //     c.grid === item.design.customProps.grid &&
      //     c.constellations === item.design.customProps.constellations,
      // );

      // if (pendantCatalog && pendantCatalog.length > 0) {
      //   return {
      //     contentId: pendantCatalog[0].id,
      //     contentType: "product",
      //     contentName: facebookGroupTitle, // catalog[0].title,
      //     contentCategory: facebookContentCategory,
      //   };
      // }

      // return {
      //   contentId: "TNS-US-PEN-SCG",
      //   contentType: "product",
      //   contentName: "Pendant",
      //   contentCategory: facebookContentCategoryForPendant,
      // };
    }

    const catalog = facebookCatalog.filter(
      (c) =>
        ((item.isCanvas === true && c.type === "canvas") || (item.isCanvas !== true && c.type !== "canvas")) &&
        c.themeId === item.design.customProps.themeId &&
        ((c.orientation === `square` && sizeIdIsSquare(item.design.customProps.sizeId)) ||
          (c.orientation !== `square` && !sizeIdIsSquare(item.design.customProps.sizeId))),
    );

    if (catalog && catalog.length > 0) {
      return {
        contentId: catalog[0].id,
        contentType: "product",
        contentName: facebookGroupTitle, // catalog[0].title,
        contentCategory: facebookContentCategory,
        contentGroupId: "",
      };
    }

    return {
      contentId: "",
      contentType: "product",
      contentName: "",
      contentCategory: facebookContentCategory,
      contentGroupId: "",
    };
  };

  /**
   * Inserts Events that look like this:
   *
   * fbq('track', 'AddToCart', {
   *   content_type: 'product',
   *   content_name: 'Custom Star Map',
   *   content_category: 'Home & Garden > Decor > Artwork > Posters, Prints, & Visual Artwork',
   *   contents: [
   *     {
   *       id: 'NSAQCP18x24',
   *       quantity: 1
   *     },
   *     {
   *       id: 'NSGBCP24x32',
   *       quantity: 1
   *     }],
   *   currency: 'USD',
   *   value: 140,
   * });
   *
   */
  const logFacebookItemsEvent = (eventType: string, items: ICartItem[], currency: string, value: string, fbcMinRequired: boolean): void => {
    const { contentType, contentName, contentCategory } = getFacebookCatalogEntryForAllItems();

    const catalogItems = items.map((item) => {
      const {
        contentId,
        contentName: itemContentName,
        contentCategory: itemContentCategory,
        contentGroupId: itemContentGroupId,
      } = getFacebookCatalogEntryForItem(item);
      return {
        id: contentId,
        name: itemContentName,
        category: itemContentCategory,
        quantity: item.copies,
        ...(itemContentGroupId && itemContentGroupId !== "" ? { group_id: itemContentGroupId } : null),
      };
    });

    // Custom Data:
    // this._value = value;
    // this._currency = currency;
    // this._content_name = content_name;
    // this._content_category = content_category;
    // this._content_ids = content_ids;
    // this._contents = contents;
    // this._content_type = content_type;
    // this._order_id = order_id;
    // this._predicted_ltv = predicted_ltv;
    // this._num_items = num_items;
    // this._search_string = search_string;
    // this._status = status;
    // this._item_number = item_number;
    // this._delivery_category = delivery_category;
    // this._custom_properties = custom_properties;
    const customData = {
      content_ids: catalogItems.map((x) => x.id),
      content_type: contentType,
      content_name: catalogItems.length === 1 ? catalogItems[0].name : contentName,
      content_category: catalogItems.length === 1 ? catalogItems[0].category : contentCategory,
      num_items: catalogItems.length,
      currency: currency.toUpperCase(),
      value,
      delivery_category: deliveryCategory,
    };

    sendFbTrackingEvent(eventType, customData, fbcMinRequired);
  };

  const logPinterestItemsEvent = (
    eventType: string,
    items: ICartItem[],
    currency: string,
    total: string,
    discountCode?: string,
    orderIdPurchased?: string,
  ): void => {
    if (window !== undefined && window.pintrk !== undefined) {
      const totalValue = `${parseFloat(total)}`.toLowerCase() !== "nan" ? parseFloat(total) : 0;

      let productCategory = "Wall Art";
      if (items.every((x) => x.isPendant)) {
        productCategory = "Jewelry";
      }

      window.pintrk("track", eventType, {
        ...(orderIdPurchased === undefined || orderIdPurchased === null || orderIdPurchased === "" ? null : { order_id: orderIdPurchased }),
        currency: currency && currency !== "" ? currency.toUpperCase() : "USD",
        value: totalValue > 0 ? totalValue : 0,
        order_quantity: items.length,
        ...(discountCode !== undefined && discountCode !== "" ? { promo_code: discountCode } : null),
        line_items: items.map((item) => {
          const { contentId, contentName } = getFacebookCatalogEntryForItem(item);

          let productPrice = 0;
          if (item.price && item.price !== null && item.price.grossPrice && item.price.grossPrice > 0) {
            productPrice = item.price.grossPrice / 100;
          }

          return {
            product_id: contentId,
            product_name: contentName,
            product_price: productPrice,
            product_quantity: 1,
            product_category: productCategory,
          };
        }),
      });
    }
  };

  const trackPageView = (): void => {
    // ************
    // PINTEREST
    // console.log(`Log PINTEREST Page View`);

    if (window !== undefined && window.pintrk !== undefined) {
      window.pintrk("track", "pagevisit");
    }

    // ************
    // FACEBOOK
    initPixel();

    // console.log(`Log Page View`);

    // Page View
    ReactPixel.pageView();

    // Bing Page View
    BingPixel.logPageView();

    // Server Side?
  };

  const trackPinterestPageView = (): void => {
    // Server Side?
  };

  const trackDesignPageContentView = () => {
    // Log View Content
    // console.info("FB: Logging content view.");

    const { contentIds, contentType, contentName, contentCategory } = getFacebookCatalogEntryForAllItems();
    sendFbTrackingEvent(
      "ViewContent",
      {
        content_ids: contentIds,
        content_type: contentType,
        content_name: contentName,
        content_category: contentCategory,
      },
      false,
    );

    // BingPixel.logViewContent()
  };

  const logAddToCart = (items, currency: string, value: string): void => {
    // ************
    // PINTEREST
    logPinterestItemsEvent("AddToCart", items, currency, value);

    // ************
    // FACEBOOK
    if (currency && currency !== "" && value && Number.parseFloat(value) > 0) {
      logFacebookItemsEvent("AddToCart", items, currency, value, false);
    }

    // Bing
    BingPixel.addProductToCart(items, currency, Number.parseFloat(value));
  };

  const trackPurchase = (
    orderIdPurchased: string,
    total: string,
    currency: string,
    shippingPrice: string,
    coupon: string,
    discountAmount: number,
    items: ICartItem[],
  ) => {
    // ************
    // PINTEREST
    logPinterestItemsEvent("Purchase", items, currency, total, coupon, orderIdPurchased);

    // ************
    // FACEBOOK
    setOrderId(orderIdPurchased);

    // Bing
    BingPixel.addPurchase(orderIdPurchased, Number.parseFloat(total), items, currency);

    logFacebookItemsEvent("Purchase", items, currency, total, true);
  };

  const trackPurchaseUpsell = async (
    orderIdPurchased: string,
    total: string,
    currency: string,
    coupon: string,
    item: any,
    upsellTotal: number,
    itemsLength: number,
  ): Promise<void> => {
    console.info("FB: Logging purchase.");
    // ************
    // PINTEREST
    const upsellOrderId =
      orderIdPurchased !== undefined && orderIdPurchased !== null && orderIdPurchased !== "" ? `${orderIdPurchased}_ups` : "";
    logPinterestItemsEvent("Purchase", [item], currency, total, coupon, upsellOrderId);

    // ************
    // FACEBOOK
    logFacebookItemsEvent("Purchase", [item], currency, total, true);

    // Bing
    BingPixel.addPurchase(orderIdPurchased, Number.parseFloat(total), [item], currency);
  };

  const trackSaveDesign = async (item: ICartItem, currency: string, productPriceCents: number) => {
    if (currency !== null && currency !== "" && productPriceCents !== null) {
      // ************
      // PINTEREST
      logPinterestItemsEvent("Lead", [item], currency.toUpperCase(), productPriceCents <= 0 ? `0` : (productPriceCents / 100).toString());

      // ************
      // FACEBOOK
      logFacebookItemsEvent(
        "AddToWishlist",
        [item],
        currency.toUpperCase(),
        productPriceCents <= 0 ? `0` : (productPriceCents / 100).toString(),
        true,
      );
    }
  };

  const trackDropAHint = async (item: ICartItem, currency: string, productPriceCents: number) => {
    if (currency !== null && currency !== "" && productPriceCents !== null) {
      // ************
      // PINTEREST
      logPinterestItemsEvent("Lead", [item], currency.toUpperCase(), productPriceCents <= 0 ? `0` : (productPriceCents / 100).toString());

      // ************
      // FACEBOOK
      logFacebookItemsEvent(
        "DropAHint",
        [item],
        currency.toUpperCase(),
        productPriceCents <= 0 ? `0` : (productPriceCents / 100).toString(),
        true,
      );
    }
  };

  const trackAddProductToCart = async (item: ICartItem, currencyParam: string, productPriceCentsParam: number) => {
    let currency = currencyParam || "USD";
    let productPriceCents = productPriceCentsParam || 6000;

    if (currency === undefined || currency === null || currency === "") {
      currency = "USD";
    }
    if (productPriceCents === undefined || productPriceCents === null || productPriceCents <= 0) {
      productPriceCents = 6000;
    }

    logAddToCart(
      [item],
      currency.toUpperCase(),
      productPriceCents === null || productPriceCents <= 0 ? `0` : (productPriceCents / 100).toString(),
    );
  };

  const trackCheckoutStarted = (_orderId: string, items: ICartItem[], totalPriceDollarsOrEuros: string, currency: string) => {
    // ************
    // FACEBOOK
    logFacebookItemsEvent("InitiateCheckout", items, currency, totalPriceDollarsOrEuros.toString(), false);

    // dataLayerPush({ ecommerce: null });  // Clear the previous ecommerce object.
    // dataLayerPush({
    //   'event': 'addToCart',
    //   'ecommerce': {
    //     'currencyCode': 'EUR',
    //     'add': {
    //       'products': [{
    //         'name': 'Night Sky - Print Only',
    //         'id': '12345', // product id
    //         'price': '60.00',
    //         'brand': 'Night Sky',
    //         'category': 'Portrait',
    //         'variant': 'Midnight Blue',
    //         'quantity': 1,
    //         'dimension2':{{coordinates}},
    //         'dimension3':{{print size}},
    //         'dimension4':{{Time}},
    //         'dimension5':{{Date}}
    //       }]
    //     }
    //   },
    //   'user_id':'XXXXXX',
    //   'page_category':'Product',
    //   'date_time': '19/09/2020 - 17:31:00'
    // });

    // Bing
    BingPixel.logStartCheckout(items, Number.parseFloat(totalPriceDollarsOrEuros), currency);
  };

  // const trackFbRemoveProductFromCart = (item, shippingPrice, isFrame, orderId: string) => {
  const trackFbRemoveProductFromCart = () => {
    // todo:
  };

  const setTrackingData = (data) => {
    if (data.pixelTestEventCode && data.pixelTestEventCode !== "") {
      setPixelTestEventCode(data.pixelTestEventCode);
    }
    if (data.customerId && data.customerId !== "") {
      setCustomerId(data.customerId);
    }
    if (data.orderId && data.orderId !== "") {
      setOrderId(data.orderId);
    }
    if (data.email && data.email !== "") {
      setCustomerEmail(data.email);
    }
    if (data.phone && data.phone !== "") {
      setCustomerPhone(data.phone);
    }
    if (data.firstName && data.firstName !== "") {
      setCustomerFirstName(data.firstName);
    }
    if (data.lastName && data.lastName !== "") {
      setCustomerLastName(data.lastName);
    }
    if (data.city && data.city !== "") {
      setCustomerCity(data.city);
    }
    if (data.stateCode && data.stateCode !== "") {
      setCustomerStateCode(data.stateCode);
    }
    if (data.zipCode && data.zipCode !== "") {
      setCustomerZipCode(data.zipCode);
    }
    if (data.countryCode && data.countryCode !== "") {
      setCustomerCountryCode(data.countryCode);
    }
  };

  const state = {
    // Helper Functions
    trackDesignPageContentView,
    logAddToCart,

    trackPageView,
    trackSaveDesign,
    trackDropAHint,
    trackAddProductToCart,
    trackCheckoutStarted,

    trackPurchase,
    trackPurchaseUpsell,
    trackFbRemoveProductFromCart,

    //
    setTrackingData,
    sendFbTrackingEvent,

    // Customer Data Values
    customerId,
    customerEmail,
    customerPhone,
    customerFirstName,
    customerLastName,
    customerCity,
    customerStateCode,
    customerZipCode,
    customerCountryCode,

    // Customer Data Setters
    setCustomerId,
    setCustomerEmail,
    setCustomerPhone,
    setCustomerFirstName,
    setCustomerLastName,
    setCustomerCity,
    setCustomerStateCode,
    setCustomerZipCode,
    setCustomerCountryCode,

    trackPinterestPageView,
  };

  /**
   * PAGE LOAD EVENT:
   */
  useEffect(() => {
    // Ensure Pixel Loaded (with up to date adv matching data)
    initPixel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <Provider value={state}>{children}</Provider>;
};

const withTrackingData = (Component) => {
  const WithTrackingData = (props) => <Consumer>{(trackingDataProps) => <Component {...trackingDataProps} {...props} />}</Consumer>;

  WithTrackingData.getInitialProps = async (context) => ({
    ...(Component.getInitialProps ? await Component.getInitialProps(context) : {}),
  });

  const displayName = Component.displayName || Component.name || `Unknown`;
  WithTrackingData.displayName = `WithTrackingData(${displayName})`;

  return WithTrackingData;
};

export { TrackingDataProvider, Consumer as TrackingDataConsumer, TrackingDataContext, withTrackingData };
