import env from "../config/environment";

export const facebookContentCategory = "Home & Garden > Decor > Artwork > Posters, Prints, & Visual Artwork";
export const facebookContentCategoryForPendant = "Apparel & Accessories > Jewelry";
export const facebookGroupId = "NSSTARMAP";
export const facebookGroupTitle = "Custom Star Map";

export const { FBP_TRACKING_ID } = env;

export const facebookPendantCatalog = [
  { id: "TNS-US-PEN-S", type: "pendant", stars: true, constellations: false, grid: false },
  { id: "TNS-US-PEN-C", type: "pendant", stars: false, constellations: true, grid: false },
  { id: "TNS-US-PEN-G", type: "pendant", stars: false, constellations: false, grid: true },
  { id: "TNS-US-PEN-SCG", type: "pendant", stars: true, constellations: true, grid: true },
  { id: "TNS-US-PEN-SC", type: "pendant", stars: true, constellations: true, grid: false },
  { id: "TNS-US-PEN-SG", type: "pendant", stars: true, constellations: false, grid: true },
  { id: "TNS-US-PEN-CG", type: "pendant", stars: false, constellations: true, grid: true },

  // "TNS-US-PEN-S",
  // "TNS-US-PEN-C",
  // "TNS-US-PEN-G",
  // "TNS-US-PEN-SCG",

  // "TNS-US-PEN-SC",
  // "TNS-US-PEN-CS",
  // "TNS-US-PEN-SC",

  // "TNS-US-PEN-T",
  // "TNS-US-PEN-TD",
  // "TNS-US-PEN-TM",
  // "TNS-US-PEN-TC",

  // "TNS-US-PEN-M",
  // "TNS-US-PEN-MS",
  // "TNS-US-PEN-MC",
  // "TNS-US-PEN-SMC",
  // "TNS-US-PEN-MSDT",
  // "TNS-US-PEN-MST",
  // "TNS-US-PEN-CD",
  // "TNS-US-PEN-TMD",
];

export const facebookCatalog = [
  { id: "TNS-SQ-B-AM-P", themeId: "aquamarine", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-W-AM-P", themeId: "aquamarine", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-WT-AM-P", themeId: "aquamarine", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-US-SQ-B", themeId: "black", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-B-B-P", themeId: "black", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-W-B-P", themeId: "black", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-WT-B-P", themeId: "black", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-US-P-SQ-B-1", themeId: "black", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-B-B-W-P", themeId: "blackWhite", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-W-B-W-P", themeId: "blackWhite", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-WT-B-W-P", themeId: "blackWhite", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-US-P-SQ-MB-W", themeId: "blackWhite", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-B-GB-P", themeId: "grayblue", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-W-GB-P", themeId: "grayblue", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-WT-GB-P", themeId: "grayblue", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-US-SQ-MB", themeId: "midnightblue", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-B-MB-P", themeId: "midnightblue", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-W-MB-P", themeId: "midnightblue", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-WT-MB-P", themeId: "midnightblue", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-US-P-SQ-MB", themeId: "midnightblue", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-US-SQ-MB-W", themeId: "midnightblueWhite", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-B-MB-W-P", themeId: "midnightblueWhite", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-W-MB-W-P", themeId: "midnightblueWhite", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-SQ-WT-MB-W-P", themeId: "midnightblueWhite", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-US-P-SQ-MB-W", themeId: "midnightblueWhite", sizeId: "12x12", type: "print", orientation: "square" },
  { id: "TNS-US-P-B", themeId: "black", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-B-B-P", themeId: "black", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-W-B-P", themeId: "black", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-WT-B-P", themeId: "black", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-US-P-B-1", themeId: "black", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-US-P-B-W", themeId: "blackWhite", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-B-B-W-P", themeId: "blackWhite", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-W-B-W-P", themeId: "blackWhite", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-WT-B-W-P", themeId: "blackWhite", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-US-P-B-W-1", themeId: "blackWhite", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-US-P-GB", themeId: "grayblue", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-B-GB-P", themeId: "grayblue", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-W-GB-P", themeId: "grayblue", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-WT-GB-P", themeId: "grayblue", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-US-P-GB-1", themeId: "grayblue", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-US-P-GB-W", themeId: "grayblueWhite", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-B-BG-W-P", themeId: "grayblueWhite", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-W-GB-W-P", themeId: "grayblueWhite", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-WT-GB-W-P", themeId: "grayblueWhite", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-US-P-GB-W-1", themeId: "grayblueWhite", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-US-P-MB", themeId: "midnightblue", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-B-MB-P", themeId: "midnightblue", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-W-MB-P", themeId: "midnightblue", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-WT-MB-P", themeId: "midnightblue", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-US-P-MB-1", themeId: "midnightblue", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-B-MB-W-P", themeId: "midnightblueWhite", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-P-W-MB-W-P", themeId: "midnightblueWhite", sizeId: "12x16", type: "print", orientation: "portrait" },
  { id: "TNS-C-SQ-AM-P", themeId: "aquamarine", sizeId: "12x12", type: "canvas", orientation: "square" },
  { id: "TNS-C-SQ-AM-W-P", themeId: "aquamarineWhite", sizeId: "12x12", type: "canvas", orientation: "square" },
  { id: "TNS-C-SQ-B-P", themeId: "black", sizeId: "12x12", type: "canvas", orientation: "square" },
  { id: "TNS-C-SQ-B-W-P", themeId: "blackWhite", sizeId: "12x12", type: "canvas", orientation: "square" },
  { id: "TNS-US-C-SQ-GB", themeId: "grayblue", sizeId: "12x12", type: "canvas", orientation: "square" },
  { id: "TNS-C-SQ-GB-P", themeId: "grayblue", sizeId: "12x12", type: "canvas", orientation: "square" },
  { id: "TNS-US-C-SQ-GB-W", themeId: "grayblueWhite", sizeId: "12x12", type: "canvas", orientation: "square" },
  { id: "TNS-C-SQ-GB-W-P", themeId: "grayblueWhite", sizeId: "12x12", type: "canvas", orientation: "square" },
  { id: "TNS-US-C-SQ-MB", themeId: "midnightblue", sizeId: "12x12", type: "canvas", orientation: "square" },
  { id: "TNS-C-SQ-MB-P", themeId: "midnightblue", sizeId: "12x12", type: "canvas", orientation: "square" },
  { id: "TNS-US-C-SQ-MB-W", themeId: "midnightblueWhite", sizeId: "12x12", type: "canvas", orientation: "square" },
  { id: "TNS-C-SQ-MB-W-P", themeId: "midnightblueWhite", sizeId: "12x12", type: "canvas", orientation: "square" },
  { id: "TNS-C-B-P", themeId: "black", sizeId: "12x16", type: "canvas", orientation: "portrait" },
  { id: "TNS-C-B-W-P", themeId: "blackWhite", sizeId: "12x16", type: "canvas", orientation: "portrait" },
  { id: "TNS-US-C-GB", themeId: "grayblue", sizeId: "12x16", type: "canvas", orientation: "portrait" },
  { id: "TNS-C-GB-P", themeId: "grayblue", sizeId: "12x16", type: "canvas", orientation: "portrait" },
  { id: "TNS-C-GB-W-P", themeId: "grayblueWhite", sizeId: "12x16", type: "canvas", orientation: "portrait" },
  { id: "TNS-US-C-MB", themeId: "midnightblue", sizeId: "12x16", type: "canvas", orientation: "portrait" },
  { id: "TNS-C-MB-P", themeId: "midnightblue", sizeId: "12x16", type: "canvas", orientation: "portrait" },
  { id: "TNS-US-C-MB-1", themeId: "midnightblue", sizeId: "12x16", type: "canvas", orientation: "portrait" },
  { id: "TNS-C-MB-W-P", themeId: "midnightblueWhite", sizeId: "12x16", type: "canvas", orientation: "portrait" },
];

// export const facebookCatalog = [
//   {
//     id: "NSAQWL12x16",
//     title: 'Custom Star Map - Aquamarine / White - 16" x 12"',
//     themeId: "aquamarineWhite",
//     sizeId: "12x16",
//     orientation: "landscape",
//   },
//   { id: "NSBKCL12x16", title: 'Custom Star Map - Black - 16" x 12"', themeId: "black", sizeId: "12x16", orientation: "landscape" },
//   {
//     id: "NSBKWL12x16",
//     title: 'Custom Star Map - Black / White - 16" x 12"',
//     themeId: "blackWhite",
//     sizeId: "12x16",
//     orientation: "landscape",
//   },
//   { id: "NSGBCL12x16", title: 'Custom Star Map - Grey Blue - 16" x 12"', themeId: "grayblue", sizeId: "12x16", orientation: "landscape" },
//   {
//     id: "NSGBWL12x16",
//     title: 'Custom Star Map - Grey Blue / White - 16" x 12"',
//     themeId: "grayblueWhite",
//     sizeId: "12x16",
//     orientation: "landscape",
//   },
//   {
//     id: "NSMBCL12x16",
//     title: 'Custom Star Map - Midnight Blue - 16" x 12"',
//     themeId: "midnightblue",
//     sizeId: "12x16",
//     orientation: "landscape",
//   },
//   {
//     id: "NSMBWL12x16",
//     title: 'Custom Star Map - Midnight Blue / White - 16" x 12"',
//     themeId: "midnightblueWhite",
//     sizeId: "12x16",
//     orientation: "landscape",
//   },
//   {
//     id: "NSAQCL18x24",
//     title: 'Custom Star Map - Aquamarine - 24" x 18"',
//     themeId: "aquamarine",
//     sizeId: "18x24",
//     orientation: "landscape",
//   },
//   {
//     id: "NSAQWL18x24",
//     title: 'Custom Star Map - Aquamarine / White - 24" x 18"',
//     themeId: "aquamarineWhite",
//     sizeId: "18x24",
//     orientation: "landscape",
//   },
//   { id: "NSBKCL18x24", title: 'Custom Star Map - Black - 24" x 18"', themeId: "black", sizeId: "18x24", orientation: "landscape" },
//   {
//     id: "NSBKWL18x24",
//     title: 'Custom Star Map - Black / White - 24" x 18"',
//     themeId: "blackWhite",
//     sizeId: "18x24",
//     orientation: "landscape",
//   },
//   { id: "NSGBCL18x24", title: 'Custom Star Map - Grey Blue - 24" x 18"', themeId: "grayblue", sizeId: "18x24", orientation: "landscape" },
//   {
//     id: "NSGBWL18x24",
//     title: 'Custom Star Map - Grey Blue / White - 24" x 18"',
//     themeId: "grayblueWhite",
//     sizeId: "18x24",
//     orientation: "landscape",
//   },
//   {
//     id: "NSMBCL18x24",
//     title: 'Custom Star Map - Midnight Blue - 24" x 18"',
//     themeId: "midnightblue",
//     sizeId: "18x24",
//     orientation: "landscape",
//   },
//   {
//     id: "NSMBWL18x24",
//     title: 'Custom Star Map - Midnight Blue / White - 24" x 18"',
//     themeId: "midnightblueWhite",
//     sizeId: "18x24",
//     orientation: "landscape",
//   },
//   {
//     id: "NSAQCL24x32",
//     title: 'Custom Star Map - Aquamarine - 32" x 24"',
//     themeId: "aquamarine",
//     sizeId: "24x32",
//     orientation: "landscape",
//   },
//   {
//     id: "NSAQWL24x32",
//     title: 'Custom Star Map - Aquamarine / White - 32" x 24"',
//     themeId: "aquamarineWhite",
//     sizeId: "24x32",
//     orientation: "landscape",
//   },
//   { id: "NSBKCL24x32", title: 'Custom Star Map - Black - 32" x 24"', themeId: "black", sizeId: "24x32", orientation: "landscape" },
//   {
//     id: "NSBKWL24x32",
//     title: 'Custom Star Map - Black / White - 32" x 24"',
//     themeId: "blackWhite",
//     sizeId: "24x32",
//     orientation: "landscape",
//   },
//   { id: "NSGBCL24x32", title: 'Custom Star Map - Grey Blue - 32" x 24"', themeId: "grayblue", sizeId: "24x32", orientation: "landscape" },
//   {
//     id: "NSGBWL24x32",
//     title: 'Custom Star Map - Grey Blue / White - 32" x 24"',
//     themeId: "grayblueWhite",
//     sizeId: "24x32",
//     orientation: "landscape",
//   },
//   {
//     id: "NSMBCL24x32",
//     title: 'Custom Star Map - Midnight Blue - 32" x 24"',
//     themeId: "midnightblue",
//     sizeId: "24x32",
//     orientation: "landscape",
//   },
//   {
//     id: "NSMBWL24x32",
//     title: 'Custom Star Map - Midnight Blue / White - 32" x 24"',
//     themeId: "midnightblueWhite",
//     sizeId: "24x32",
//     orientation: "landscape",
//   },
//   { id: "NSAQCP12x16", title: 'Custom Star Map - Aquamarine - 12" x 16"', themeId: "aquamarine", sizeId: "12x16", orientation: "portrait" },
//   {
//     id: "NSAQWP12x16",
//     title: 'Custom Star Map - Aquamarine / White - 12" x 16"',
//     themeId: "aquamarineWhite",
//     sizeId: "12x16",
//     orientation: "portrait",
//   },
//   { id: "NSBKCP12x16", title: 'Custom Star Map - Black - 12" x 16"', themeId: "black", sizeId: "12x16", orientation: "portrait" },
//   {
//     id: "NSBKWP12x16",
//     title: 'Custom Star Map - Black / White - 12" x 16"',
//     themeId: "blackWhite",
//     sizeId: "12x16",
//     orientation: "portrait",
//   },
//   { id: "NSGBCP12x16", title: 'Custom Star Map - Grey Blue - 12" x 16"', themeId: "grayblue", sizeId: "12x16", orientation: "portrait" },
//   {
//     id: "NSGBWP12x16",
//     title: 'Custom Star Map - Grey Blue / White - 12" x 16"',
//     themeId: "grayblueWhite",
//     sizeId: "12x16",
//     orientation: "portrait",
//   },
//   {
//     id: "NSMBCP12x16",
//     title: 'Custom Star Map - Midnight Blue - 12" x 16"',
//     themeId: "midnightblue",
//     sizeId: "12x16",
//     orientation: "portrait",
//   },
//   {
//     id: "NSMBWP12x16",
//     title: 'Custom Star Map - Midnight Blue / White - 12" x 16"',
//     themeId: "midnightblueWhite",
//     sizeId: "12x16",
//     orientation: "portrait",
//   },
//   { id: "NSAQCP18x24", title: 'Custom Star Map - Aquamarine - 18" x 24"', themeId: "aquamarine", sizeId: "18x24", orientation: "portrait" },
//   {
//     id: "NSAQWP18x24",
//     title: 'Custom Star Map - Aquamarine / White - 18" x 24"',
//     themeId: "aquamarineWhite",
//     sizeId: "18x24",
//     orientation: "portrait",
//   },
//   { id: "NSBKCP18x24", title: 'Custom Star Map - Black - 18" x 24"', themeId: "black", sizeId: "18x24", orientation: "portrait" },
//   {
//     id: "NSBKWP18x24",
//     title: 'Custom Star Map - Black / White - 18" x 24"',
//     themeId: "blackWhite",
//     sizeId: "18x24",
//     orientation: "portrait",
//   },
//   { id: "NSGBCP18x24", title: 'Custom Star Map - Grey Blue - 18" x 24"', themeId: "grayblue", sizeId: "18x24", orientation: "portrait" },
//   {
//     id: "NSGBWP18x24",
//     title: 'Custom Star Map - Grey Blue / White - 18" x 24"',
//     themeId: "grayblueWhite",
//     sizeId: "18x24",
//     orientation: "portrait",
//   },
//   {
//     id: "NSMBCP18x24",
//     title: 'Custom Star Map - Midnight Blue - 18" x 24"',
//     themeId: "midnightblue",
//     sizeId: "18x24",
//     orientation: "portrait",
//   },
//   {
//     id: "NSMBWP18x24",
//     title: 'Custom Star Map - Midnight Blue / White - 18" x 24"',
//     themeId: "midnightblueWhite",
//     sizeId: "18x24",
//     orientation: "portrait",
//   },
//   { id: "NSAQCP24x32", title: 'Custom Star Map - Aquamarine - 24" x 32"', themeId: "aquamarine", sizeId: "24x32", orientation: "portrait" },
//   {
//     id: "NSAQWP24x32",
//     title: 'Custom Star Map - Aquamarine / White - 24" x 32"',
//     themeId: "aquamarineWhite",
//     sizeId: "24x32",
//     orientation: "portrait",
//   },
//   { id: "NSBKCP24x32", title: 'Custom Star Map - Black - 24" x 32"', themeId: "black", sizeId: "24x32", orientation: "portrait" },
//   {
//     id: "NSBKWP24x32",
//     title: 'Custom Star Map - Black / White - 24" x 32"',
//     themeId: "blackWhite",
//     sizeId: "24x32",
//     orientation: "portrait",
//   },
//   { id: "NSGBCP24x32", title: 'Custom Star Map - Grey Blue - 24" x 32"', themeId: "grayblue", sizeId: "24x32", orientation: "portrait" },
//   {
//     id: "NSGBWP24x32",
//     title: 'Custom Star Map - Grey Blue / White - 24" x 32"',
//     themeId: "grayblueWhite",
//     sizeId: "24x32",
//     orientation: "portrait",
//   },
//   {
//     id: "NSMBCP24x32",
//     title: 'Custom Star Map - Midnight Blue - 24" x 32"',
//     themeId: "midnightblue",
//     sizeId: "24x32",
//     orientation: "portrait",
//   },
//   {
//     id: "NSMBWP24x32",
//     title: 'Custom Star Map - Midnight Blue / White - 24" x 32"',
//     themeId: "midnightblueWhite",
//     sizeId: "24x32",
//     orientation: "portrait",
//   },
//   {
//     id: "NSMBWS16x16C",
//     title: 'Midnight Blue / White - Canvas - 16" x 16"',
//     themeId: "midnightblueWhite",
//     sizeId: "16x16",
//     orientation: "square",
//   },
//   {
//     id: "NSMBWS12x12C",
//     title: "Midnight Blue / White - Canvas 12x12",
//     themeId: "midnightblueWhite",
//     sizeId: "12x12",
//     orientation: "square",
//   },
//   { id: "NSGBCS16x16C", title: 'Gray Blue - Canvas - 16" x 16"', themeId: "grayblue", sizeId: "16x16", orientation: "square" },
//   { id: "NSMBWP18x24C", title: 'Midnight Blue - Canvas - 18" x 24"', themeId: "midnightblue", sizeId: "18x24", orientation: "portrait" },
//   { id: "NSMBWP18x24C2", title: 'Midnight Blue - Canvas - 18" x 24"', themeId: "midnightblue", sizeId: "18x24", orientation: "portrait" },
//   { id: "NSMBWP24x32C", title: 'Midnight Blue - Canvas - 24" x 32"', themeId: "midnightblue", sizeId: "24x32", orientation: "portrait" },
//   { id: "NSGBCP18x24C", title: 'Grey Blue - Canvas - 18" x 24"', themeId: "grayblue", sizeId: "18x24", orientation: "portrait" },
//   { id: "NSMBCS12x12F", title: 'Midnight Blue - Framed - 12" x 12"', themeId: "midnightblue", sizeId: "12x12", orientation: "square" },
//   {
//     id: "NSMBWS16x16F",
//     title: 'Midnight Blue / White - Framed - 16" x 16"',
//     themeId: "midnightblueWhite",
//     sizeId: "16x16",
//     orientation: "square",
//   },
//   { id: "NSBKCS12x12F", title: 'Black - Framed - 12" x 12"', themeId: "black", sizeId: "12x12", orientation: "square" },
//   { id: "NSBKWS16x16F", title: 'Black - Framed - 16" x 16"', themeId: "black", sizeId: "16x16", orientation: "square" },
//   { id: "NSSGBCP18x24C", title: "Gray Blue - Canvas 18x24 ", themeId: "grayblue", sizeId: "18x24", orientation: "portrait" },
//   { id: "NSSMBCP18x24C", title: "Aquamarine - Canvas 18x24 ", themeId: "aquamarine", sizeId: "18x24", orientation: "portrait" },
//   { id: "NSSMBWP18x24C", title: "Midnight Blue - Canvas 18x24 ", themeId: "midnightblue", sizeId: "18x24", orientation: "portrait" },
//   { id: "NSSBKCS16x16C", title: "Black - Canvas 18x24 ", themeId: "black", sizeId: "18x24", orientation: "portrait" },
//   { id: "NSSGBCS16x16C", title: 'Gray Blue - Canvas - 16" x 16"', themeId: "grayblue", sizeId: "16x16", orientation: "square" },
//   { id: "NSSAQCS16x16C", title: 'Aquamarine - Canvas - 16" x 16"', themeId: "aquamarine", sizeId: "16x16", orientation: "square" },
//   { id: "NSSMBCS16x16C", title: 'Midnight Blue - Canvas - 16" x 16"', themeId: "midnightblue", sizeId: "16x16", orientation: "square" },
//   { id: "NSSBKCS16x16C2", title: 'Black - Canvas - 16" x 16"', themeId: "black", sizeId: "16x16", orientation: "square" },
//   { id: "NSSBKCS16x16WF", title: 'Black - 16" x 16" White Frame', themeId: "black", sizeId: "16x16", orientation: "square" },
//   { id: "NSSMBCS16x16WF", title: 'Midnight Blue - 16" x 16" White Frame', themeId: "midnightblue", sizeId: "16x16", orientation: "square" },
//   { id: "NSSAQCS16x16WF", title: 'Aquamarine - 16" x 16" White Frame', themeId: "aquamarine", sizeId: "16x16", orientation: "square" },
//   { id: "NSSGBCS16x16WF", title: 'Gray Blue - 16" x 16" White Frame', themeId: "grayblue", sizeId: "16x16", orientation: "square" },
//   {
//     id: "NSSMBWS16x16WF",
//     title: 'Midnight Blue / White - 16" x 16" White Frame',
//     themeId: "midnightblueWhite",
//     sizeId: "16x16",
//     orientation: "square",
//   },
//   { id: "NSSBKWS16x16WF", title: 'Black / White - 16" x 16" White Frame', themeId: "blackWhite", sizeId: "16x16", orientation: "square" },
//   { id: "NSSBKCS16x16NF", title: 'Black - 16" x 16" Wood Frame', themeId: "black", sizeId: "16x16", orientation: "square" },
//   { id: "NSSMBCS16x16NF", title: 'Midnight Blue - 16" x 16" Wood Frame', themeId: "midnightblue", sizeId: "16x16", orientation: "square" },
//   { id: "NSSAQCS16x16NF", title: 'Aquamarine - 16" x 16" Wood Frame', themeId: "aquamarine", sizeId: "16x16", orientation: "square" },
//   { id: "NSSGBCS16x16NF", title: 'Gray Blue - 16" x 16" Wood Frame', themeId: "grayblue", sizeId: "16x16", orientation: "square" },
//   {
//     id: "NSSMBWS16x16NF",
//     title: 'Midnight Blue / White - 16" x 16" Wood Frame',
//     themeId: "midnightblueWhite",
//     sizeId: "16x16",
//     orientation: "square",
//   },
//   { id: "NSSBKWS16x16NF", title: 'Black / White - 16" x 16" Wood Frame', themeId: "blackWhite", sizeId: "16x16", orientation: "square" },
//   { id: "NSSBKCS16x16BF", title: 'Black - 16" x 16" Black Frame', themeId: "black", sizeId: "16x16", orientation: "square" },
//   { id: "NSSMBCS16x16BF", title: 'Midnight Blue - 16" x 16" Black Frame', themeId: "midnightblue", sizeId: "16x16", orientation: "square" },
//   { id: "NSSAQCS16x16BF", title: 'Aquamarine - 16" x 16" Black Frame', themeId: "aquamarine", sizeId: "16x16", orientation: "square" },
//   { id: "NSSGBCS16x16BF", title: 'Gray Blue - 16" x 16" Black Frame', themeId: "grayblue", sizeId: "16x16", orientation: "square" },
//   {
//     id: "NSSMBWS16x16BF",
//     title: 'Midnight Blue / White - 16" x 16" Black Frame',
//     themeId: "midnightblueWhite",
//     sizeId: "16x16",
//     orientation: "square",
//   },
//   { id: "NSSBKWS16x16BF", title: 'Black / White - 16" x 16" Black Frame', themeId: "blackWhite", sizeId: "16x16", orientation: "square" },
// ];
