import ReactSCPixel from "./snapchat_mod";
import { ICartItem, IShippingObject } from "../../interfaces";
import env from "../../config/environment";
import { snapchatCatalog, snapchatContentCategory } from "../../resources/snapchat";

const { SC_TRACKING_ID } = env;

const init = (): void => {
  // console.log(`SC: Init: ${SC_TRACKING_ID}`);
  const options = {
    debug: process.env.node_env === "local",
  };

  ReactSCPixel.init(SC_TRACKING_ID, {}, options);
};

const logSCEvent = (name = "", data = {}): void => {
  if (name && data && Object.keys(data).length !== 0) {
    ReactSCPixel.track(name, data);
  }
};

const logPageView = (): void => {
  // console.log(`SC: Page View: `, pathName);
  // Log View Content
  ReactSCPixel.pageView();
};

const logDesignPageContentView = (): void => {
  // Log View Content
  // console.log(`SC: View All Content`);

  // View all Content
  const snapchatItems = snapchatCatalog.map((item) => item.id);
  if (snapchatItems && snapchatItems.length > 0) {
    logSCEvent("VIEW_CONTENT", {
      item_category: snapchatContentCategory,
      item_ids: snapchatCatalog.map((item) => item.id),
    });
  }
};

/**
 * Get Snapchat item_id for iem
 */
const getSnapchatItemIdForItem = (item: ICartItem): string => {
  // if Pendant
  if (item.isPendant) {
    return "NSPENDANTV1";
  }

  let catalog = snapchatCatalog.filter(
    (c) =>
      c.themeId === item.design.customProps.themeId &&
      c.orientation === item.design.customProps.orientation &&
      c.sizeId === item.design.customProps.sizeId,
  );

  if (!catalog || catalog.length <= 0) {
    catalog = snapchatCatalog.filter(
      (c) => c.themeId === item.design.customProps.themeId && c.orientation === item.design.customProps.orientation && c.sizeId === "18x24",
    );
  }

  if (catalog && catalog.length > 0) {
    return catalog[0].id;
  }

  return "";
};

/**
 * List of Snapchat Ids
 */
const getSnapchatIdsForItems = (items: ICartItem[]): string[] => {
  if (!items) {
    console.error(`No items passed to snapchat`);
  }

  return items.map((item) => getSnapchatItemIdForItem(item)).filter((x) => x !== "");
};

const logStartCheckout = (): void => {
  // console.log(`SC: Start Checkout`);
  logSCEvent("START_CHECKOUT");
};

const logViewContent = (items: ICartItem[]): void => {
  const snapchatItems = getSnapchatIdsForItems(items);
  if (snapchatItems && snapchatItems.length > 0) {
    // console.log(`SC: View Content: ${JSON.stringify(snapchatItems)}`);

    logSCEvent("VIEW_CONTENT", {
      item_category: snapchatContentCategory,
      item_ids: snapchatCatalog.map((item) => item.id),
    });
  } else {
    // console.log(`SC: View Content: Items not found in catalog`);
  }
};

const addProductToCart = async (item: ICartItem, _shipping: IShippingObject, _orderId: string): Promise<void> => {
  const snapchatItems = getSnapchatIdsForItems([item]);
  if (snapchatItems && snapchatItems.length > 0) {
    // Send each purchased product from cart to GA
    if (item.price !== undefined && item.price.currency !== null && item.price.grossPrice !== null) {
      logSCEvent("ADD_CART", {
        currency: item.price.currency.toUpperCase(),
        price: item.price.grossPrice / 100,
        item_category: snapchatContentCategory,
        item_ids: snapchatItems,
      });
    }
  } else {
    console.log(`SC: Add to Cart: Items not found in catalog`);
  }
};

const addPurchase = (
  orderId: string,
  total: number,
  items: ICartItem[],
  currency: string,
  _coupon: string,
  _discountAmount: number,
): void => {
  const snapchatItems = getSnapchatIdsForItems(items);

  // console.log(`SC: Add Purchase: Price: ${total} - Items: ${JSON.stringify(snapchatItems)}`);
  logSCEvent("PURCHASE", {
    currency,
    price: total,
    transaction_id: orderId,
    item_category: snapchatContentCategory,
    item_ids: snapchatItems,
  });
};

const setUserEmail = (email: string): void => {
  // console.log(`SC: Set User Email: ${email}`);
  ReactSCPixel.setUserEmail(SC_TRACKING_ID, email);
};

export default {
  init,
  logPageView,
  logDesignPageContentView,
  logStartCheckout,
  logViewContent,
  addProductToCart,
  addPurchase,
  setUserEmail,
};
