// Zone two states.
export default {
  ak: {
    name: "alaska",
  },
  as: {
    name: "american samoa",
  },
  fm: {
    name: "federal states of micronesia",
  },
  gu: {
    name: "guam",
  },
  hi: {
    name: "hawaii",
  },
  mh: {
    name: "marshall islands",
  },
  mp: {
    name: "northern marianas",
  },
  pw: {
    name: "palau",
  },
  pr: {
    name: "puerto rico",
  },
  vi: {
    name: "virgin islands",
  },
  aa: {
    name: "armed forces america",
  },
  ae: {
    name: "armed forces europe",
  },
  ap: {
    name: "armed forces pacific",
  },
};
