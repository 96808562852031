import axios from "axios";
import getCartWithoutImages from "../utils/cart";
import env from "../config/environment";
import { ICart } from "../interfaces";

const { TNS_PLATFORM_API, DEFAULT_PRODUCT_TYPE } = env;

const createCart = async (
  cart: ICart,
  countryCode?: string,
  giftNote?: string,
  hostname?: string,
  getThumbnailUrls?: boolean,
): Promise<{ cartId: string; cart: ICart }> => {
  // console.log(`CREATE CART: ${JSON.stringify(cart)}`);
  try {
    // First, make sure we don't send the base64 images from the items.
    const cartWithoutImages = getCartWithoutImages(cart);
    const d = {
      cart: cartWithoutImages,
      countryCode,
      productType: cart.productType || DEFAULT_PRODUCT_TYPE,
      ...(giftNote ? { giftNote } : {}),
      ...(hostname ? { hostname } : {}),
      ...(getThumbnailUrls ? { getThumbnailUrls } : {}),
    };

    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/cart/create`, d);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const updateCart = async (
  cartId: string,
  cart: ICart,
  countryCode?: string,
  giftNote?: string,
  hostname?: string,
  getThumbnailUrls?: boolean,
): Promise<{ cartId: string; cart: ICart }> => {
  // console.log(`UPDATE CART ${cartId}: ${JSON.stringify(cart)}`);
  try {
    const cartWithoutImages = getCartWithoutImages(cart);

    const d = {
      cart: cartWithoutImages,
      cartId,
      countryCode,
      ...(giftNote ? { giftNote } : {}),
      ...(hostname ? { hostname } : {}),
      ...(getThumbnailUrls ? { getThumbnailUrls } : {}),
    };

    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/cart/update`, d);
    return data;
  } catch (err) {
    console.error(err.response.data);
    throw Error(err.response.data.message);
  }
};

const createOrUpdateCart = async (
  cart: ICart,
  countryCode?: string,
  cartId?: string,
  giftNote?: string,
  hostname?: string,
  getThumbnailUrls?: boolean,
): Promise<{ cartId: string; cart: ICart }> => {
  return cartId && cartId !== ""
    ? updateCart(cartId, cart, countryCode, giftNote, hostname, getThumbnailUrls)
    : createCart(cart, countryCode, giftNote, hostname, getThumbnailUrls);
};

const getById = async (cartId: string): Promise<{ cartId: string; cart: ICart; giftNote?: string }> => {
  try {
    const { data } = await axios.get(`${TNS_PLATFORM_API}/api/cart/${cartId}`);
    return data;
  } catch (err) {
    console.error(err.response.data);
    throw Error(err.response.data.message);
  }
};

export default {
  createOrUpdateCart,
  getById,
};
