import ReactGA from "react-ga";
import configApi from "../../api/config";
import env from "../../config/environment";
import { checkoutSteps } from "../../context/OrderContext";
import { ICartItem, IShippingObject } from "../../interfaces";

const { APP_NAME, GA_TRACKING_ID } = env;

const initGA = (): void => {
  // // console.info("GA: Initializing with ID:", GA_TRACKING_ID);
  // ReactGA.initialize(GA_TRACKING_ID, {
  //   debug: false,
  //   gaOptions: {
  //     siteSpeedSampleRate: 100,
  //   },
  // });
  // ReactGA.plugin.require("ec");
};

const logPageView = (pathName: string): void => {
  if (!window.GA_INITIALIZED) {
    initGA();

    window.GA_INITIALIZED = true;

    // Log Page View
    // console.info("GA: Logging page view.");

    ReactGA.set({ page: pathName });
    ReactGA.pageview(pathName);
  }
};

const logEvent = (category = "", action = ""): void => {
  if (category && action) {
    // console.info(`GA: Logging event ${category} ${action}.`);
    ReactGA.event({ category, action });
  }
};

const logException = (description = "", fatal = false): void => {
  if (description) {
    // console.info("GA: Logging exception.");
    ReactGA.exception({ description, fatal });
  }
};

const logPaymentMethod = (action = "", paymentMethod = "", totalValue = 0): void => {
  ReactGA.event({
    category: "PaymentMethod",
    action,
    label: paymentMethod,
    value: totalValue || 0,
  });
};

const addPurchase = (
  orderId: string,
  total: string,
  currency: string,
  shippingPrice: string,
  coupon: string,
  discountAmount: number,
  items: ICartItem[],
): void => {
  // console.info("GA: Logging Purchase.");
  // Send each purchased product from cart to GA
  const arr = items.map(async (item: ICartItem) => {
    if (item.price === undefined || item.price?.grossPrice === null) {
      console.error(`Error recording purchase action in GA`);
      return;
    }

    const variant = await configApi.getTnsSku({
      ...item,
      previewImageUrl: undefined,
    });
    const itemPricing = item.price.grossPrice / 100;

    // eslint-disable-next-line no-underscore-dangle
    const discountedPrice = itemPricing - (itemPricing / 100) * discountAmount; // <-- what is this?
    const price = coupon ? discountedPrice : itemPricing;

    let category = "Starmap";
    if (item.isDigital) {
      category = "Digital";
    }
    if (item.isPendant) {
      category = "Pendant";
    }
    ReactGA.plugin.execute("ec", "addProduct", {
      id: item.id,
      name: variant,
      category,
      quantity: item.copies || 1,
      price: price.toString(),
    });
  });

  Promise.all(arr).then(() => {
    // Send Purchase action to GA
    ReactGA.plugin.execute("ec", "setAction", "purchase", {
      id: orderId,
      affiliation: APP_NAME,
      revenue: total.toString(),
      currency,
      shipping: shippingPrice,
      coupon,
    });

    logEvent("Purchase", "Purchased products");
  });
};

const addPurchaseUpsell = async (orderId: string, _total: string, currency: string, coupon: string, item: ICartItem): Promise<void> => {
  // console.info("GA: Logging purchase.");
  // Send upsell from cart to GA
  const variant = await configApi.getTnsSku({
    ...item,
    previewImageUrl: undefined,
  });

  if (item.price === undefined || item.price.productPrice === null || item.price.grossPrice === null) {
    // asdf log error
    console.error(`Error recording upsell analytics`);
    return;
  }

  const productPriceUnits = item.price.productPrice / 100;
  const grossPriceUnits = item.price.grossPrice / 100;

  let category = "Starmap";
  if (item.isDigital) {
    category = "Digital";
  }
  if (item.isPendant) {
    category = "Pendant";
  }
  ReactGA.plugin.execute("ec", "addProduct", {
    id: orderId,
    name: variant,
    category,
    quantity: item.copies || 1,
    price: productPriceUnits.toString(),
  });

  // Send Purchase action to GA
  ReactGA.plugin.execute("ec", "setAction", "purchase", {
    id: orderId,
    affiliation: APP_NAME,
    revenue: grossPriceUnits.toString(),
    currency,
    coupon,
  });

  logEvent("Purchase", "Purchased products");

  // Correct total order revenue if it's second or more upsell or there is just 1 Starmap purchased
  ReactGA.plugin.execute("ec", "setAction", "purchase", {
    id: orderId,
    affiliation: APP_NAME,
    revenue: grossPriceUnits.toString(),
    currency,
  });

  logEvent("Upsell", "Upsell revenue alignment");
};

const addProduct = async (
  item: ICartItem,
  shipping: IShippingObject,
  isFrame: boolean,
  orderId: string,
): Promise<{ variant: string; itemPrice: number } | null> => {
  try {
    const variant = await configApi.getTnsSku({
      ...item,
      previewImageUrl: undefined,
    });

    // If item is a frame use it's own 'pricing' object
    const itemPrice =
      item.price && item.price !== undefined && item.price.productPrice !== undefined && item.price.productPrice !== null
        ? item.price.productPrice || 0
        : 0;

    let category = "Starmap";
    if (item.isDigital) {
      category = "Digital";
    }
    if (item.isPendant) {
      category = "Pendant";
    }
    ReactGA.plugin.execute("ec", "addProduct", {
      id: item.id,
      name: variant,
      category,
      quantity: item.copies || 1,
      price: (itemPrice > 0 ? itemPrice / 100 : 0).toString(),
    });

    return { variant, itemPrice };
  } catch (e) {
    console.error(e);
    return null;
  }
};

const addProductToCart = async (item: ICartItem, shipping: IShippingObject, isFrame: boolean, orderId: string): Promise<void> => {
  await addProduct(item, shipping, isFrame, orderId);
  ReactGA.plugin.execute("ec", "setAction", "add");
  logEvent("Cart", "Added item to cart");
};

const removeProductFromCart = (item: ICartItem, shipping: IShippingObject, isFrame: boolean, orderId: string): void => {
  addProduct(item, shipping, isFrame, orderId);
  ReactGA.plugin.execute("ec", "setAction", "remove");
  logEvent("Cart", "Removed item from cart");
};

const checkoutCartStep = (items: ICartItem[], isEditing: boolean, shipping: IShippingObject, orderId: string): void => {
  items.forEach((item) => {
    addProduct(item, shipping, false, orderId);
  });
  ReactGA.plugin.execute("ec", "setAction", "checkout", {
    step: checkoutSteps.CART,
    option: isEditing ? "Previously saved order" : "Newly created order",
  });
};

const checkoutInformationStep = (): void => {
  ReactGA.plugin.execute("ec", "setAction", "checkout", {
    step: checkoutSteps.INFORMATION,
  });
};

const checkoutShippingStep = (shippingType: string): void => {
  ReactGA.plugin.execute("ec", "setAction", "checkout", {
    step: checkoutSteps.SHIPPING,
    ...(shippingType ? { option: shippingType } : {}),
  });
};

const checkoutPaymentStep = (): void => {
  ReactGA.plugin.execute("ec", "setAction", "checkout", {
    step: checkoutSteps.PAYMENT,
  });
};

const checkoutThanksStep = (): void => {
  ReactGA.plugin.execute("ec", "setAction", "checkout", {
    step: checkoutSteps.THANKS,
  });
};

export default {
  initGA,
  logPageView,
  logEvent,
  logException,
  addPurchase,
  addPurchaseUpsell,
  logPaymentMethod,
  addProduct,
  addProductToCart,
  removeProductFromCart,
  checkoutCartStep,
  checkoutInformationStep,
  checkoutShippingStep,
  checkoutPaymentStep,
  checkoutThanksStep,
};
