import BingTracking from "react-tracking-bing";
import env from "../../config/environment";
import { ICartItem } from "../../interfaces";

const { BING_TRACKING_ID, DEFAULT_PRODUCT_TYPE } = env;
const bingInitialized = false;

const logPageView = (): void => {
  if (!window) return;

  if (!bingInitialized) {
    BingTracking.initialize(BING_TRACKING_ID);
    // bingInitialized = true;
  }

  // Log View Content
  BingTracking.trackPageview();
};

// // Log View Content
// const logDesignPageContentView = (): void => {
//   // const productType = items && items.length > 0 && items.every((item) => item.isPendant) ? "pendant" : DEFAULT_PRODUCT_TYPE;
//   BingTracking.getTracker().push("event", "view_item", {
//     // event_category: productType,
//     event_label: "View Item",
//     // event_value: totalPrice,
//     // revenue_value: totalPrice,
//     // currency: currency ? currency.toUpperCase() : "",
//   });
// };

const logStartCheckout = (items: ICartItem[], totalPrice: number | null, currency: string): void => {
  if (!window) return;

  if (!bingInitialized) {
    BingTracking.initialize(BING_TRACKING_ID);
    // bingInitialized = true;
  }

  // console.log(`Bing: Start Checkout`);
  const productType = items && items.length > 0 && items.every((item) => item.isPendant) ? "pendant" : DEFAULT_PRODUCT_TYPE;
  BingTracking.getTracker().push("event", "begin_checkout", {
    event_category: productType,
    event_label: "Begin Checkout",
    event_value: totalPrice,
    revenue_value: totalPrice,
    currency: currency && currency !== "" && currency !== "NaN" ? currency.toUpperCase() : null,
  });
};

const logViewContent = (item: ICartItem): void => {
  if (!window) return;

  if (!bingInitialized) {
    BingTracking.initialize(BING_TRACKING_ID);
    // bingInitialized = true;
  }

  const productType = item && item.isPendant ? "pendant" : DEFAULT_PRODUCT_TYPE;
  const itemValue = item && item.price && item.price.productPrice && item.price.productPrice >= 0 ? item.price.productPrice : 0;

  BingTracking.getTracker().push("event", "view_item", {
    event_category: productType,
    event_label: "View Content",
    event_value: itemValue,
    revenue_value: itemValue,
    currency: item && item.price && item.price.currency ? item.price.currency.toUpperCase() : null,
  });
};

const addProductToCart = async (items: ICartItem[], currency: string, itemValue: number): Promise<void> => {
  if (!window) return;

  if (!bingInitialized) {
    BingTracking.initialize(BING_TRACKING_ID);
    // bingInitialized = true;
  }

  const productType = items && items.length > 0 && items.every((item) => item.isPendant) ? "pendant" : DEFAULT_PRODUCT_TYPE;

  BingTracking.getTracker().push("event", "add_to_cart", {
    event_category: productType,
    event_label: "Add To Cart",
    event_value: itemValue,
    revenue_value: itemValue,
    currency: currency && currency !== "" && currency !== "NaN" ? currency.toUpperCase() : null,
  });
};

const addPurchase = (_orderId: string, total: number, items: ICartItem[], currency: string): void => {
  if (!window) return;

  if (!bingInitialized) {
    BingTracking.initialize(BING_TRACKING_ID);
    // bingInitialized = true;
  }

  const productType = items && items.length > 0 && items.every((item) => item.isPendant) ? "pendant" : DEFAULT_PRODUCT_TYPE;

  BingTracking.getTracker().push("event", "purchase", {
    event_category: productType,
    event_label: "Purchase",
    event_value: total,
    revenue_value: total,
    currency: currency && currency !== "" && currency !== "NaN" ? currency.toUpperCase() : null,
  });
};

export default {
  logPageView,
  // logDesignPageContentView,
  logStartCheckout,
  logViewContent,
  addProductToCart,
  addPurchase,
};
