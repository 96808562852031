import { IAddress } from "../interfaces";
import { countries } from "../resources/countries";
import stateProvinceAbbreviations from "../resources/state_province_abbreviations";

export const objectIsEmpty = (obj: object) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

// status: string; // awaitingPayment / paymentProcessing / awaitingSubmission / preparingForProduction / inProduction / shipped / complete
export const getFriendlyOrderStatus = (orderStatus: string): string => {
  switch (orderStatus) {
    case "awaitingPayment":
      return "Awaiting Payment";
    case "paymentProcessing":
      return "Payment Processing";
    case "awaitingSubmission":
      return "Awaiting Submission";
    case "preparingForProduction":
      return "Preparing For Production";
    case "inProduction":
      return "In Production";
    case "shipped":
      return "Shipped";
    case "complete":
      return "Complete";
    default:
      return orderStatus;
  }
};

export const addressObjectToStringLine = (addressObj: IAddress): string => {
  const { firstName, lastName, street, city, state, zipCode, countryCode } = addressObj;

  return `${firstName} ${lastName}, ${street}, ${city}, ${state}, ${zipCode}, ${countryCode}`;
};

export const getShortNameforProductName = (productName: string): string => {
  switch (productName) {
    case "starmap":
      return "Custom Star Map";
    default:
      return "Item";
  }
};

export const getFrameName = (frameColor: string): string => (frameColor === "natural" ? "wood" : frameColor);

export const injectScript = (document, id, src, onload = () => {}) => {
  const fjs = document.getElementsByTagName("script")[0];
  if (document.getElementById(id)) {
    return;
  }
  const js = document.createElement("script");
  js.id = id;
  js.onload = onload;
  js.src = src;
  fjs.parentNode.insertBefore(js, fjs);
};

export const removeScript = (document, id) => {
  const doc = document.getElementById(id);
  if (doc) {
    doc.parentNode.removeChild(doc);
  }
};

export const formatAddress = (address: IAddress): string => {
  const { city, countryCode, firstName, lastName, state, street, zipCode }: IAddress = address;

  const countryName = countries[countryCode.substring(0, 2)] ? countries[countryCode.substring(0, 2)].name : countryCode.substring(0, 2);
  return `${firstName} ${lastName}, ${street}, ${city}, ${state}, ${zipCode}, ${countryName}`;
};

export const getCountryName = (address: IAddress): string => {
  const { city, countryCode, firstName, lastName, state, street, zipCode }: IAddress = address;

  return countries[countryCode.substring(0, 2)] ? countries[countryCode.substring(0, 2)].name : countryCode.substring(0, 2);
};

export const getShippingValue = (cc, shipType, upgrade, valType) => {
  // const euCountries = ["FR", "ES", "NL", "HU"];
  let image = "/shipping/carrier-postNL.png";
  let text = "PostNL";
  // US NOT ACCURATE? IS DHL NOW - Also APO forward army bases (AF, AP, AE) ship USPS
  // if (cc === "US") {
  //   if (shipType === "standard") {
  //     if (upgrade) {
  //       image = "/shipping/carrier-ups.png";
  //       text = "UPS Ground";
  //     } else {
  //       image = "/shipping/carrier-usps.png";
  //       text = "USPS Ground";
  //     }
  //   } else {
  //     image = "/shipping/carrier-ups.png";
  //     text = "UPS";
  //   }
  // } else
  if (cc === "CA") {
    if (shipType === "standard") {
      image = "/shipping/carrier-rm.png";
      text = "Royal Mail to Canada Post";
    } else {
      image = "/shipping/carrier-ups.png";
      text = "UPS Ground";
    }
  } else if (cc === "IE") {
    if (shipType === "standard") {
      image = "/shipping/carrier-postNL.png";
      text = "PostNL to An Post";
    } else {
      image = "/shipping/carrier-dpd.png";
      text = "DPD";
    }
  } else if (cc === "GB") {
    if (shipType === "standard") {
      image = "/shipping/carrier-rm.png";
      text = "Royal Mail";
    } else {
      image = "/shipping/carrier-dpd.png";
      text = "DPD";
    }
    // Logos for US, CA, UK, IE Only
    // } else if (cc === "AU" || cc === "NZ") {
    //   if (shipType === "standard") {
    //     image = "/shipping/carrier-aus-post.png";
    //     text = "AUS Post";
    //   } else {
    //     image = "/shipping/carrier-dpd.png";
    //     text = "DPD";
    //   }
    // } else if (euCountries.includes(cc)) {
    //   if (shipType === "standard") {
    //     image = "/shipping/carrier-postNL.png";
    //     text = "PostNL";
    //   } else {
    //     image = "/shipping/carrier-dpd.png";
    //     text = "DPD";
    //   }
  } else {
    image = "/shipping/carrier-postNL.png";
    text = "PostNL";
  }

  if (valType === "text") {
    return text;
  }
  return image;
};

enum OrderTypes {
  Print = "print",
  Framed = "framed",
  Canvas = "canvas",
  PendantOnyx = "pendant_onyx",
  PendantSilver = "pendant",
  PendantGold = "pendant_gold",
}

// export const getFormatFromProps = (isFramed: boolean, isCanvas: boolean, isDigital: boolean, isPendant: boolean): string => {
//   let format = "Print";
//   if (isPendant) {
//     format = "Pendant";
//   } else if (isFramed) {
//     format = "Framed";
//   } else if (isCanvas) {
//     format = "Canvas";
//   } else if (isDigital) {
//     format = "Digital";
//   } else {
//     format = "Print";
//   }

//   return format;
// };

/**
 * Takes an order timings object for the country and calculates when the earliest and latest
 * fulfillment and delivery timing for the items - used to give a single "order will be delivered
 * in between X and Y days when there are print and frame items mixed in the cart
 */
export const mergeOrderTimings = (
  orderTimesForCountry: any,
  isExpress: boolean,
  orderContainsPrints: boolean,
  orderContainsFrames: boolean,
  orderContainsCanvas: boolean,
  orderContainsOnyxPendant: boolean,
  orderContainsSilverPendant: boolean,
  orderContainsGoldPendant: boolean,
) => {
  const shippingSpeedKey = isExpress ? "express" : "standard";

  // Use Frame timings for Canvas for the moment
  let orderTimes;

  // console.log(`orderContainsPrints: ${orderContainsPrints}`);

  if (orderContainsPrints) {
    orderTimes = orderTimesForCountry[OrderTypes.Print][shippingSpeedKey];
  }

  if (orderContainsFrames) {
    if (!orderTimes || !orderTimes.fulfillment) {
      orderTimes = orderTimesForCountry[OrderTypes.Framed][shippingSpeedKey];
    } else {
      orderTimes = {
        fulfillment: [
          Math.min(orderTimes.fulfillment[0], orderTimesForCountry[OrderTypes.Framed][shippingSpeedKey].fulfillment[0]),
          Math.max(orderTimes.fulfillment[1], orderTimesForCountry[OrderTypes.Framed][shippingSpeedKey].fulfillment[1]),
        ],
        shipping: [
          Math.min(orderTimes.shipping[0], orderTimesForCountry[OrderTypes.Framed][shippingSpeedKey].shipping[0]),
          Math.max(orderTimes.shipping[1], orderTimesForCountry[OrderTypes.Framed][shippingSpeedKey].shipping[1]),
        ],
      };
    }
  }

  if (orderContainsCanvas) {
    if (!orderTimes || !orderTimes.fulfillment) {
      orderTimes = orderTimesForCountry[OrderTypes.Canvas][shippingSpeedKey];
    } else {
      orderTimes = {
        fulfillment: [
          Math.min(orderTimes.fulfillment[0], orderTimesForCountry[OrderTypes.Canvas][shippingSpeedKey].fulfillment[0]),
          Math.max(orderTimes.fulfillment[1], orderTimesForCountry[OrderTypes.Canvas][shippingSpeedKey].fulfillment[1]),
        ],
        shipping: [
          Math.min(orderTimes.shipping[0], orderTimesForCountry[OrderTypes.Canvas][shippingSpeedKey].shipping[0]),
          Math.max(orderTimes.shipping[1], orderTimesForCountry[OrderTypes.Canvas][shippingSpeedKey].shipping[1]),
        ],
      };
    }
  }

  if (orderContainsOnyxPendant) {
    if (!orderTimes || !orderTimes.fulfillment) {
      orderTimes = orderTimesForCountry[OrderTypes.PendantOnyx][shippingSpeedKey];
    } else {
      orderTimes = {
        fulfillment: [
          // NOTE OrderTypes.framed - we we're using Frame timings for Canvas for the moment
          Math.min(orderTimes.fulfillment[0], orderTimesForCountry[OrderTypes.PendantOnyx][shippingSpeedKey].fulfillment[0]),
          Math.max(orderTimes.fulfillment[1], orderTimesForCountry[OrderTypes.PendantOnyx][shippingSpeedKey].fulfillment[1]),
        ],
        shipping: [
          // NOTE OrderTypes.framed - we we're using Frame timings for Canvas for the moment
          Math.min(orderTimes.shipping[0], orderTimesForCountry[OrderTypes.PendantOnyx][shippingSpeedKey].shipping[0]),
          Math.max(orderTimes.shipping[1], orderTimesForCountry[OrderTypes.PendantOnyx][shippingSpeedKey].shipping[1]),
        ],
      };
    }
  }

  if (orderContainsSilverPendant) {
    if (!orderTimes || !orderTimes.fulfillment) {
      orderTimes = orderTimesForCountry[OrderTypes.PendantSilver][shippingSpeedKey];
    } else {
      orderTimes = {
        fulfillment: [
          // NOTE OrderTypes.framed - we we're using Frame timings for Canvas for the moment
          Math.min(orderTimes.fulfillment[0], orderTimesForCountry[OrderTypes.PendantSilver][shippingSpeedKey].fulfillment[0]),
          Math.max(orderTimes.fulfillment[1], orderTimesForCountry[OrderTypes.PendantSilver][shippingSpeedKey].fulfillment[1]),
        ],
        shipping: [
          // NOTE OrderTypes.framed - we we're using Frame timings for Canvas for the moment
          Math.min(orderTimes.shipping[0], orderTimesForCountry[OrderTypes.PendantSilver][shippingSpeedKey].shipping[0]),
          Math.max(orderTimes.shipping[1], orderTimesForCountry[OrderTypes.PendantSilver][shippingSpeedKey].shipping[1]),
        ],
      };
    }
  }

  if (orderContainsGoldPendant) {
    if (!orderTimes || !orderTimes.fulfillment) {
      orderTimes = orderTimesForCountry[OrderTypes.PendantGold][shippingSpeedKey];
    } else {
      orderTimes = {
        fulfillment: [
          // NOTE OrderTypes.framed - we we're using Frame timings for Canvas for the moment
          Math.min(orderTimes.fulfillment[0], orderTimesForCountry[OrderTypes.PendantGold][shippingSpeedKey].fulfillment[0]),
          Math.max(orderTimes.fulfillment[1], orderTimesForCountry[OrderTypes.PendantGold][shippingSpeedKey].fulfillment[1]),
        ],
        shipping: [
          // NOTE OrderTypes.framed - we we're using Frame timings for Canvas for the moment
          Math.min(orderTimes.shipping[0], orderTimesForCountry[OrderTypes.PendantGold][shippingSpeedKey].shipping[0]),
          Math.max(orderTimes.shipping[1], orderTimesForCountry[OrderTypes.PendantGold][shippingSpeedKey].shipping[1]),
        ],
      };
    }
  }

  return {
    submit: [0, 1], // same for express/standard
    ...orderTimes,
  };
};

/**
 * Quick test if a sizeId 12x12 or 16x16 is square
 */
export const sizeIdIsSquare = (sizeId: string): boolean => {
  if (!sizeId || sizeId === "") {
    throw new Error(`Invalid sizeId passed to sizeIdIsSquare`);
  }

  const [a, b] = sizeId.split("x");
  return a.trim() === b.trim();
};

/**
 * Get a state code for a state, e.g. "CA" for "California"
 */
export const convertStateToStateCode = (state?: string | null): string | null => {
  try {
    if (!state || state === "" || state.trim() === "") {
      return null;
    }

    const found = stateProvinceAbbreviations.filter((x) => x[0].toLowerCase() === state.trim().toLowerCase());
    if (found && found.length > 0) {
      return found[0][1];
    }
  } catch (error) {
    console.error(error);
  }
  return null;
};

/**
 * Get the current page/source URL
 */
export const getSourceUrl = (): string | null => {
  try {
    if (window && window.location && window.location.href && window.location.href !== "") {
      return window.location.href;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};
