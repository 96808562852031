import React, { useEffect, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/pt";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/nl";
import "moment/locale/pl";
import queryString from "query-string";
import configApi from "../api/config";
import orderApi from "../api/order";
import cartApi from "../api/cart";
import alertsApi from "../api/alerts";
import env from "../config/environment";
import { Router, NextI18NextInstance } from "../i18n";
import { mergeOrderTimings, convertStateToStateCode } from "../utils/utils";
import usZoneTwoStates from "../resources/usZoneTwoStates";
import { TrackingDataContext } from "./TrackingDataContext";
import { IAddress, IAlert, IBillingObject, ICart, ICartItem, IDiscountObject, IOrderData, IShippingObject } from "../interfaces";
import ga4Analytics from "../utils/analytics/ga4Analytics";
import PendantMaterials from "../resources/pendantMaterials";

const { BLACKLISTED_COUNTRIES, ACTIVE_SITE } = env;

let OrderContext;
const { Provider, Consumer } = (OrderContext = React.createContext({}));

export const checkoutSteps = {
  CART: 1,
  INFORMATION: 2,
  SHIPPING: 3,
  PAYMENT: 4,
  THANKS: 5,
};

export enum PaymentMethodType {
  PAYMENT_REQUEST = "PAYMENT_REQUEST",
  PAYPAL = "PAYPAL",
  CARD = "CARD",
  STRIPE = "STRIPE",
  AMAZONPAY = "AMAZONPAY",
  NONE = "",
}

export type IOrderContext = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  stripeEurCustomerId: string;
  stripeUsdCustomerId: string;
  stripeSourceId: string;
  stripeSourceType: string;
  setCustomerId: React.Dispatch<React.SetStateAction<string>>;
  setStripeSourceId: React.Dispatch<React.SetStateAction<string>>;
  setStripeSourceType: React.Dispatch<React.SetStateAction<string>>;
  customerId: string;
  setOrderId: React.Dispatch<React.SetStateAction<string>>;
  orderId: string;
  friendlyOrderId: string;
  paymentIntentId: string;
  canMarket: boolean;
  setCanMarket: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddNewPrintDesign: () => void;
  handleAddNewPendantDesign: () => void;
  goToCart: (cart: ICart, shippingParam: IShippingObject, billingParam: IBillingObject, addedItem: ICartItem) => Promise<void>;
  goToCartAndSaveDesign: (
    cart: ICart,
    shippingParam: IShippingObject,
    billingParam: IBillingObject,
    newItem: ICartItem,
    forceSaveDesign?: boolean | undefined,
  ) => Promise<void>;
  dropAHint: (
    cart: ICart,
    shippingParam: IShippingObject,
    billingParam: IBillingObject,
    newItem: ICartItem,
    forceSaveDesign?: boolean | undefined,
  ) => Promise<void>;
  goToShipping: (cart: ICart, _shipping: IShippingObject, _billing: IBillingObject) => Promise<IDiscountObject>;
  goToShippingStep: () => Promise<boolean>;
  goToInformationStep: (cart?: ICart) => Promise<void>;
  goToPaymentStep: (cart: ICart) => Promise<void>;
  goToPaymentStepSkipShipping: (cart: ICart) => Promise<void>;
  goToCartStep: () => void;
  goToThanksStep: (customerIdParam: string, orderIdParam: string) => Promise<boolean>;
  goToPaymentProcessingStep: (customerIdParam: string, orderIdParam: string, sourceIdParam?: string) => Promise<boolean>;
  goToPaymentPage: (customerIdParam: string, orderIdParam: string) => Promise<boolean>;
  // goToSaveThanks: (cart: ICart, shippingParam: IShippingObject, billingParam: IBillingObject) => Promise<void>;
  shippingInfo: (cart: ICart, shippingType: string) => { standard: JSX.Element; express: JSX.Element };
  updatePaymentIntentId: (id: string) => void;
  updatePaymentMade: (val: boolean) => void;
  makeOrder: (
    customerEmail: string,
    cart: ICart,
    orderShipping: IShippingObject,
    orderBilling: IBillingObject,
  ) => Promise<{
    customerId: string;
    stripeEurCustomerId: string;
    stripeUsdCustomerId: string;
    orderId: string;
    isReturning: boolean;
    friendlyOrderId: string;
    updatedDiscount: IDiscountObject;
  } | null>;
  saveCartInfoToDB: (
    cart: ICart,
    shippingParam?: IShippingObject | undefined,
    billingParam?: IBillingObject | undefined,
    giftNoteParam?: string | undefined,
    newItem?: ICartItem,
  ) => Promise<string>;
  saveCartInfoForShopify: (cart: ICart, getThumbnailUrls?: boolean | undefined) => Promise<any>;
  paymentMade: boolean;
  setOrder: (orderData: IOrderData) => Promise<void>;
  isOrderPaid: boolean;
  setIsOrderPaid: React.Dispatch<React.SetStateAction<boolean>>;
  isDigitalOnlySite: boolean;
  setIsDigitalOnlySite: React.Dispatch<React.SetStateAction<boolean>>;
  isReturningCustomer: boolean;
  paymentMethodId: string;
  setPaymentMethodId: React.Dispatch<React.SetStateAction<string>>;
  paymentMethodUsed: PaymentMethodType;
  setPaymentMethodUsed: (value: React.SetStateAction<PaymentMethodType>) => void;
  giftNote: string;
  setGiftNote: React.Dispatch<React.SetStateAction<string>>;
  updateShipping: (newShipping: IShippingObject) => void;
  shipping: IShippingObject;
  updateShippingType: (type: string) => Promise<void>;
  updateShippingAddressFormComplete: (val: boolean) => Promise<void>;
  updateAlertsData: (cart: ICart) => Promise<IAlert[]>;
  shippingAddressFormComplete: boolean;
  alertsData: any[];
  billing: IBillingObject;
  setBilling: React.Dispatch<React.SetStateAction<IBillingObject>>;
  alertWasShown: boolean;
  setAlertWasShown: React.Dispatch<React.SetStateAction<boolean>>;
  discountUsed: boolean;
  setDiscountUsed: React.Dispatch<React.SetStateAction<boolean>>;
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  shouldUseSameAddress: boolean;
  setShouldUseSameAddress: React.Dispatch<React.SetStateAction<boolean>>;
  isPaymentLoading: boolean;
  setIsPaymentLoading: React.Dispatch<React.SetStateAction<boolean>>;
  clearOrderContext: () => void;
  setInitialCountryCode: (countryCode: string) => void;
  updateCountryCode: (countryCode: string) => void;
  updateDeliveryTimeRanges: (countryCode: string) => Promise<any>;
  stripePaymentLoading: boolean;
  setStripePaymentLoading: React.Dispatch<React.SetStateAction<boolean>>;
  shippingTypeDisabled: (address: IAddress, isExpress: boolean) => boolean;
  cartingOut: boolean;
  setCartingOut: React.Dispatch<React.SetStateAction<boolean>>;
  getDeliveryRangeText: (
    shippingTypeParam: string,
    containsFrames: boolean,
    containsCanvas: boolean,
    containsPendant: boolean,
  ) => string | null;
  amazonPayCheckoutSessionId: string;
  setAmazonPayCheckoutSessionId: React.Dispatch<React.SetStateAction<string>>;
  setCartId: React.Dispatch<React.SetStateAction<string>>;
  cartId: string;
  setIdempotencyKey: React.Dispatch<React.SetStateAction<string>>;
  idempotencyKey: string;
  setDropAHintFormData: React.Dispatch<React.SetStateAction<string[]>>;
  dropAHintFormData: string[];
  setDropAHintDone: React.Dispatch<React.SetStateAction<boolean>>;
  dropAHintDone: boolean;
};

const initialContext = {
  paymentMethodUsed: PaymentMethodType.STRIPE,
  email: "",
  isPaymentLoading: false,
  stripePaymentLoading: false,
  isOrderPaid: false,
  isDigitalOnlySite: false,
  customerId: "",
  stripeEurCustomerId: "",
  stripeUsdCustomerId: "",
  stripeSourceId: "",
  stripeSourceType: "",
  orderId: "",
  cartId: "",
  friendlyOrderId: "",
  canMarket: true,
  paymentIntentId: "",
  paymentMethodId: "",
  paymentMade: false,
  isReturningCustomer: false,
  giftNote: "",
  shouldUseSameAddress: true,
  shippingAddressFormComplete: false,
  alertsData: [],
  alertWasShown: false,
  discountUsed: false,
  status: "",
  shipping: {
    type: "standard",
    address: {
      firstName: "",
      lastName: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      countryCode: "",
    },
  },
  billing: {
    address: {
      firstName: "",
      lastName: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      countryCode: "",
    },
  },
  countryConfig: {
    countryCode: "",
    timings: {},
  },
};

const OrderProvider = ({ children }) => {
  const [paymentMethodUsed, setPaymentMethodUsed] = useState<PaymentMethodType>(initialContext.paymentMethodUsed);
  const [email, setEmail] = useState<string>(initialContext.email);
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(initialContext.isPaymentLoading);
  const [stripePaymentLoading, setStripePaymentLoading] = useState<boolean>(initialContext.stripePaymentLoading);
  const [isOrderPaid, setIsOrderPaid] = useState<boolean>(initialContext.isOrderPaid);
  const [isDigitalOnlySite, setIsDigitalOnlySite] = useState<boolean>(initialContext.isDigitalOnlySite);
  const [customerId, setCustomerId] = useState<string>(initialContext.customerId);
  const [stripeEurCustomerId, setStripeEurCustomerId] = useState<string>(initialContext.stripeEurCustomerId);
  const [stripeUsdCustomerId, setStripeUsdCustomerId] = useState<string>(initialContext.stripeUsdCustomerId);
  const [stripeSourceId, setStripeSourceId] = useState<string>(initialContext.stripeSourceId);
  const [stripeSourceType, setStripeSourceType] = useState<string>(initialContext.stripeSourceType);
  const [orderId, setOrderId] = useState<string>(initialContext.orderId);
  const [cartId, setCartId] = useState<string>(initialContext.cartId);
  const [friendlyOrderId, setFriendlyOrderId] = useState<string>(initialContext.orderId);
  const [canMarket, setCanMarket] = useState<boolean>(initialContext.canMarket);
  const [paymentIntentId, setPaymentIntentId] = useState<string>(initialContext.paymentIntentId);
  const [paymentMethodId, setPaymentMethodId] = useState<string>(initialContext.paymentMethodId);
  const [paymentMade, setPaymentMade] = useState<boolean>(initialContext.paymentMade);
  const [isReturningCustomer, setIsReturningCustomer] = useState<boolean>(initialContext.isReturningCustomer);
  const [shouldUseSameAddress, setShouldUseSameAddress] = useState<boolean>(initialContext.shouldUseSameAddress);
  const [shippingAddressFormComplete, setShippingAddressFormComplete] = useState<boolean>(initialContext.shippingAddressFormComplete);

  const [alertsData, setAlertsData] = useState<any[]>(initialContext.alertsData);
  const [giftNote, setGiftNote] = useState<string>(initialContext.giftNote);
  const [alertWasShown, setAlertWasShown] = useState<boolean>(initialContext.alertWasShown);
  const [discountUsed, setDiscountUsed] = useState<boolean>(initialContext.discountUsed);
  const [status, setStatus] = useState<string>(initialContext.status);
  const [shipping, setShipping] = useState<IShippingObject>(initialContext.shipping);
  const [billing, setBilling] = useState<IBillingObject>(initialContext.billing);
  const [cartingOut, setCartingOut] = useState<boolean>(false);
  const [amazonPayCheckoutSessionId, setAmazonPayCheckoutSessionId] = useState<string>("");
  const [detectedCountryCode, setDetectedCountryCode] = useState<string>("");

  const [deliveryTimeRanges, setDeliveryTimeRanges] = useState<any>();
  // const [countryConfig, setCountryConfig] = useState(initialContext.countryConfig);
  // const [priceDetails, setPriceDetails] = useState();

  // Nested Context
  const { setTrackingData } = useContext(TrackingDataContext);
  const [idempotencyKey, setIdempotencyKey] = useState<string>(uuidv4());
  const [dropAHintFormData, setDropAHintFormData] = useState<string[]>([]);
  const [dropAHintDone, setDropAHintDone] = useState<boolean>(false);

  const clearOrderContext = (): void => {
    setPaymentMethodUsed(initialContext.paymentMethodUsed);
    setCustomerId(initialContext.customerId);
    setStripeEurCustomerId(initialContext.stripeEurCustomerId);
    setStripeUsdCustomerId(initialContext.stripeUsdCustomerId);
    setStripeSourceId(initialContext.stripeSourceId);
    setStripeSourceType(initialContext.stripeSourceType);
    setOrderId(initialContext.orderId);
    setCartId(initialContext.cartId);
    setFriendlyOrderId(initialContext.friendlyOrderId);
    setIsOrderPaid(initialContext.isOrderPaid);
    setIsDigitalOnlySite(initialContext.isDigitalOnlySite);
    setCanMarket(initialContext.canMarket);
    setPaymentIntentId(initialContext.paymentIntentId);
    setPaymentMethodId(initialContext.paymentMethodId);
    setPaymentMade(initialContext.paymentMade);
    setIsReturningCustomer(initialContext.isReturningCustomer);
    setIsPaymentLoading(initialContext.isPaymentLoading);
    setStripePaymentLoading(initialContext.stripePaymentLoading);
    setGiftNote(initialContext.giftNote);
    setShouldUseSameAddress(initialContext.shouldUseSameAddress);
    setShippingAddressFormComplete(initialContext.shippingAddressFormComplete);
    setAlertsData(initialContext.alertsData);
    setAlertWasShown(initialContext.alertWasShown);
    setStatus(initialContext.status);
    setDiscountUsed(initialContext.discountUsed);
    setBilling(initialContext.billing);
    setCartingOut(false);
    setAmazonPayCheckoutSessionId("");
    setIdempotencyKey(uuidv4());
    setDropAHintFormData([]);
    // setShipping(initialContext.shipping);
    // setDeliveryTimeRanges(false);
    // setEmail(initialContext.email);
  };

  const checkIfCountryCodeIsDigital = (countryCode: string): void => {
    // Set digital only if hostname is digital.the...
    const digitalOnlyCountries = [
      "AR", // Argentina
      "BZ", // Belize
      "BO", // Bolivia (Plurinational State of)
      "BR", // Brazil
      "CL", // Chile
      "CO", // Colombia
      "CR", // Costa Rica
      "CU", // Cuba
      "CW", // Curaçao
      "EC", // Ecuador
      "SV", // El Salvador
      "GF", // French Guiana
      "GD", // Grenada
      "GP", // Guadeloupe
      "GT", // Guatemala
      "GY", // Guyana
      "HT", // Haiti
      "HN", // Honduras
      "IN", // India
      "IO", // British Indian Ocean Territory
      "ID", // Indonesia
      "MX", // Mexico
      "NI", // Nicaragua
      "PA", // Panama
      "PY", // Paraguay
      "PE", // Peru
    ];
    let digitalOnly = digitalOnlyCountries.includes(countryCode);
    if (!digitalOnly) {
      try {
        if (window && window.location && window.location.hostname.indexOf("://digital.") >= 0) {
          digitalOnly = true;
        }
      } catch {
        // ignore error
      }
    }

    // Hide Gorgias on Digital
    setIsDigitalOnlySite(digitalOnly);
    if (digitalOnly) {
      try {
        // @ts-ignore
        window.document.querySelector("#gorgias-chat-container").style.display = "none";
      } catch {
        // ignore error
      }
    }
  };

  const updateShipping = (newShipping: IShippingObject): void => {
    if (!newShipping.address.countryCode || newShipping.address.countryCode === "") {
      // don't update with bad data
      return;
    }

    if (shipping.address.countryCode !== newShipping.address.countryCode || shipping.address.countryCode === "") {
      // Different country, overwrite with the new one, even if it wipes older name/street/state data (we'd want to clear that if country is different)
      setShipping({
        type: shipping.type,
        address: {
          ...shipping.address,
          ...newShipping.address,
        },
      });
      return;
    }

    // Addresses are for the same country - check if new shipping valid:
    const currentAddressPartComplete =
      shipping.address.countryCode &&
      shipping.address.countryCode !== "" &&
      ((shipping.address.state && shipping.address.state !== "") || (shipping.address.street && shipping.address.street !== ""));

    const newAddressPartComplete =
      newShipping.address.countryCode &&
      newShipping.address.countryCode !== "" &&
      ((newShipping.address.state && newShipping.address.state !== "") ||
        (newShipping.address.street && newShipping.address.street !== ""));

    /**
     * if the old address has a state or street info, we assume somebody attempted to input a version of their address;
     * if the new address only has country code, we don't overwrite that, because it's probably an incomplete version
     * from the ApplePay/Amazon/Paypal
     */
    if (currentAddressPartComplete && !newAddressPartComplete) {
      // new address is not complete - do not wipe earlier address
    } else {
      // New address has state/street info - store it!
      setShipping({
        ...shipping,
        ...newShipping,
        address: {
          ...shipping.address,
          ...newShipping.address,
          // countryCode: shipping.address.countryCode || countryCode,
        },
      });
    }
  };

  // const updateBilling = (newBilling: IBillingObject): void => {
  //   if (!newBilling.address.countryCode || newBilling.address.countryCode === "") {
  //     // don't update with bad data
  //     return;
  //   }

  //   // Addresses are for the same country - check if new shipping valid:
  //   const currentAddressPartComplete =
  //     billing.address.countryCode &&
  //     billing.address.countryCode !== "" &&
  //     ((billing.address.state && billing.address.state !== "") || (billing.address.street && billing.address.street !== ""));
  //   const currentAddress = { ...billing.address };

  //   const newAddressPartComplete =
  //     newBilling.address.countryCode &&
  //     newBilling.address.countryCode !== "" &&
  //     ((newBilling.address.state && newBilling.address.state !== "") || (newBilling.address.street && newBilling.address.street !== ""));

  //   /**
  //    * if the old address has a state or street info, we assume somebody attempted to input a version of their address;
  //    * if the new address only has country code, we don't overwrite that, because it's probably an incomplete version
  //    * from the ApplePay/Amazon/Paypal
  //    */
  //   if (currentAddressPartComplete && !newAddressPartComplete) {
  //     // new address is not complete - do not wipe earlier address
  //     setBilling({
  //       ...newBilling,
  //       address: {
  //         ...currentAddress,
  //       },
  //     });
  //   } else {
  //     setBilling({ ...newBilling });
  //   }
  // };

  const updateDeliveryTimeRanges = async (countryCode: string): Promise<any> => {
    return orderApi
      .getDeliveryTimingForCountry(countryCode)
      .then((newDeliveryTimeRanges) => {
        setDeliveryTimeRanges({ ...newDeliveryTimeRanges });
        return newDeliveryTimeRanges;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateCountryCode = (countryCode: string) => {
    updateShipping({
      ...shipping,
      address: {
        ...shipping.address,
        countryCode,
      },
    });
  };

  const setInitialCountryCode = (countryCode: string): void => {
    // Check if Digital (and redirect if necessary):
    checkIfCountryCodeIsDigital(countryCode);

    // set address:
    if ((countryCode !== shipping.address.countryCode && !shipping.address.countryCode) || shipping.address.countryCode === "") {
      updateShipping({
        ...shipping,
        address: {
          ...shipping.address,
          countryCode: shipping.address.countryCode || countryCode,
        },
      });
      return;
    }

    if (countryCode !== billing.address.countryCode) {
      setBilling(() => ({
        ...billing,
        address: {
          ...billing.address,
          countryCode: billing.address.countryCode || countryCode,
        },
      }));
    }

    // update order timings for checkout shipping description
    if (shipping.address.countryCode) {
      // update order time ranges - only when
      updateDeliveryTimeRanges(shipping.address.countryCode);
    }

    if (!countryCode && !shipping.address.countryCode) {
      console.error(`countryCode could not be detected or found in shipping.address`);
    }
  };

  const updateShippingType = async (type: string): Promise<void> => {
    setShipping({ ...shipping, type });
  };

  const updateShippingAddressFormComplete = async (val: boolean): Promise<void> => {
    setShippingAddressFormComplete(val);
  };

  // Detect PO Box
  const detectPOBox = (streetParam: string): boolean => {
    try {
      const street = streetParam.toLowerCase();
      const poBoxPattern = /^ *((#\d+)|((box|bin)[-. /\\]?\d+)|(.*p[ .]? ?(o|0)[-. /\\]? *-?((box|bin)|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin))? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)) *(number|num|#)? *\d+|(num|number|#) *\d+)/i;
      if (street.match(poBoxPattern)) {
        return true;
      }
    } catch (error) {
      // Ignore the error
    }

    return false;
  };

  const updateAlertsData = (cart: ICart): Promise<IAlert[]> => {
    const shippingType = shipping.type;
    const { countryCode, street, state: shippingAddressState } = shipping.address;
    const containsPrints = cart.items.some((i) => !i.isFramed && !i.isCanvas && !i.isPendant && !i.isDigital);
    const containsFrames = cart.items.some((i) => i.isFramed);
    const containsCanvas = cart.items.some((i) => i.isCanvas);
    const containsPendant = cart.items.some((i) => i.isPendant);
    const includesPOBox = detectPOBox(street || "");

    const containsFrameCanvasLargerThan18x24 = cart.items.some(
      (i) =>
        (i.isFramed || i.isCanvas) &&
        i.design &&
        i.design.customProps &&
        i.design.customProps.sizeId &&
        i.design.customProps.sizeId !== "" &&
        ["24X32"].indexOf(i.design.customProps.sizeId.toUpperCase()) >= 0,
    );

    // let includesPOBox = false;
    // if (street && street.toLowerCase().includes("po box")) {
    //   includesPOBox = true;
    // }

    const { phoneNumber } = cart;
    const includesPhoneNumber = !!phoneNumber;
    return alertsApi
      .getAlertsData(
        countryCode,
        includesPhoneNumber,
        includesPOBox,
        shippingType,
        containsPrints,
        containsFrames,
        containsCanvas,
        containsPendant,
        giftNote,
        shippingAddressState,
        containsFrameCanvasLargerThan18x24,
      )
      .then((data) => {
        setAlertsData(data);
        return data;
      })
      .catch(() => {});
  };

  const updatePaymentIntentId = (id: string): void => {
    setPaymentIntentId(id);
  };

  const updatePaymentMade = (val: boolean): void => {
    setPaymentMade(val);
  };

  // Used when a customer has filled in their email on the first step, to create an order immediately.
  const makeOrder = async (
    customerEmail: string,
    cart: ICart,
    orderShipping: IShippingObject,
    orderBilling: IBillingObject,
  ): Promise<{
    customerId: string;
    stripeEurCustomerId: string;
    stripeUsdCustomerId: string;
    orderId: string;
    isReturning: boolean;
    friendlyOrderId: string;
    updatedDiscount: IDiscountObject;
  } | null> => {
    try {
      // If we're editing a saved design, we want to edit order, not make a new one. This has the
      // nice side effect of dealing with browser back button actions if user goes back from the
      // checkout page since the orderId will exist at that point.
      if (orderId) {
        return orderApi.editOrder(orderId, cart, orderShipping, orderBilling, shouldUseSameAddress, giftNote);
      }
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      // alert("Something went wrong when trying to edit your order.");
    }

    try {
      let hostname: string | undefined;
      if (window && window.location && window.location.hostname && window.location.hostname !== "") {
        hostname = window.location.hostname;
      }

      const { firstName, lastName } = orderShipping.address;
      const createdOrder = await orderApi.createOrder(
        customerEmail,
        cart,
        canMarket,
        firstName || "",
        lastName || "",
        giftNote,
        orderShipping,
        orderBilling,
        shouldUseSameAddress,
        undefined,
        hostname,
      );

      setOrderId(createdOrder.orderId);
      setCustomerId(createdOrder.customerId);
      setStripeEurCustomerId(createdOrder.stripeEurCustomerId);
      setStripeUsdCustomerId(createdOrder.stripeUsdCustomerId);
      setIsReturningCustomer(createdOrder.isReturning);
      setFriendlyOrderId(createdOrder.friendlyOrderId);
      setCartId(""); // clear cart id, once we have order id

      return createdOrder;
    } catch (err) {
      console.error(err);
      // @ts-ignore
      alert("Something went wrong when trying to create your order.");
    }

    return null;
  };

  const saveCartInfoToDB = async (
    cart: ICart,
    shippingParam?: IShippingObject,
    billingParam?: IBillingObject,
    giftNoteParam?: string,
    newItem?: ICartItem,
  ): Promise<string> => {
    try {
      if (giftNoteParam !== undefined) {
        setGiftNote(giftNoteParam);
      }

      // console.log(`saveCartInfoToDB: SAVE INFP: ${JSON.stringify(newItem, null, 4)}`);

      // If we're editing a saved design, we want to edit order, not make a new one. This
      // has the nice side effect of dealing with browser back button actions if user goes
      // back from the checkout page since the orderId will exist at that point.
      if (orderId) {
        await orderApi.editOrder(
          orderId,
          cart,
          shippingParam || shipping,
          billingParam || billing,
          shouldUseSameAddress,
          giftNoteParam || giftNote || "",
        );
        return `/cart?customerId=${customerId}&orderId=${orderId}`;
      }

      let hostname: string | undefined;
      if (window && window.location && window.location.hostname && window.location.hostname !== "") {
        hostname = window.location.hostname;
      }

      if (email) {
        const { firstName, lastName } = (shippingParam || shipping).address;
        const createdOrder = await orderApi.createOrder(
          email,
          cart,
          canMarket,
          firstName || "", /// asdf should be firstName || shipping.address.firstName ... or the billing address?
          lastName || "",
          giftNoteParam || "",
          shippingParam || shipping,
          billingParam || billing,
          shouldUseSameAddress,
          undefined,
          hostname,
        );

        updateShipping(shippingParam || shipping);
        setBilling(billingParam || billing);
        setCustomerId(createdOrder.customerId);
        setStripeEurCustomerId(createdOrder.stripeEurCustomerId);
        setStripeUsdCustomerId(createdOrder.stripeUsdCustomerId);
        setOrderId(createdOrder.orderId);
        setFriendlyOrderId(createdOrder.friendlyOrderId);
        setIsReturningCustomer(createdOrder.isReturning);

        // Log new user
        ga4Analytics.logUserRegistration(createdOrder.customerId, ACTIVE_SITE);
      }

      let countryCode = "";
      if (shippingParam && shippingParam.address.countryCode && shippingParam.address.countryCode !== "") {
        countryCode = shippingParam.address.countryCode;
      } else if (shipping && shipping.address.countryCode && shipping.address.countryCode !== "") {
        countryCode = shipping.address.countryCode;
      }

      // console.log(`saveCartInfoToDB: Creatung Cart!`);

      // Save or Update Cart
      const updatedCart = await cartApi.createOrUpdateCart(cart, countryCode, cartId, giftNote, hostname);
      setCartId(cartId && cartId !== "" ? cartId : updatedCart.cartId);

      // console.log(`saveCartInfoToDB: Log Add to Cart? `);
      // Log Add to Cart
      if (newItem !== undefined) {
        // console.log(`saveCartInfoToDB: Log Add to Cart? YES!`);
        let addedItem = { ...newItem };
        if (addedItem.id && addedItem.id !== null && updatedCart.cart && updatedCart.cart.items && updatedCart.cart.items.length > 0) {
          const matches = updatedCart.cart.items.filter((x) => x.id === addedItem.id);
          if (matches && matches.length > 0) {
            addedItem = { ...matches[0] };
          }
        }

        let trackCurrency: string | null = null;
        let trackPrice: number | null = null;
        if (addedItem && addedItem.price && addedItem.price.currency && addedItem.price.productPrice) {
          trackCurrency = addedItem.price.currency;
          trackPrice = addedItem.price.productPrice;
        } else if (cart && cart.price && cart.price.currency && cart.price.productPrice) {
          // extimate price if missing:
          trackCurrency = cart.price.currency;
          trackPrice = cart.price.productPrice / Math.max(1, cart.items.length);
        }

        if (trackPrice && trackPrice > 0) {
          trackPrice /= 100;
        }

        // console.log(`saveCartInfoToDB: ga4Analytics.addProductToCart`);
        // Log Add to Cart:
        ga4Analytics.addProductToCart(null, trackCurrency || "", trackPrice, { ...addedItem });
      }

      return `/cart?cartId=${cartId && cartId !== "" ? cartId : updatedCart.cartId}`;
    } catch (err) {
      console.error(err);
      // alert("Something went wrong when trying to create your order.");
    }

    return "";
  };

  const saveCartInfoForShopify = async (cart: ICart, getThumbnailUrls?: boolean): Promise<any> => {
    try {
      let hostname: string | undefined;
      if (window && window.location && window.location.hostname && window.location.hostname !== "") {
        hostname = window.location.hostname;
      }

      let countryCode = "";
      if (shipping && shipping.address.countryCode && shipping.address.countryCode !== "") {
        countryCode = shipping.address.countryCode;
      }

      // Save or Update Cart
      const updatedCart = await cartApi.createOrUpdateCart(cart, cartId, countryCode, giftNote, hostname, getThumbnailUrls);
      setCartId(cartId && cartId !== "" ? cartId : updatedCart.cartId);
      return updatedCart;
    } catch (err) {
      console.error(err);
      // alert("Something went wrong when trying to create your order.");
    }

    return null;
  };

  const handleAddNewPrintDesign = (): void => {
    // If we're making another map, we want to clear the current one.
    setCartingOut(false);
    Router.push("/design", "/design", { shallow: true }).then(() => {
      setCartingOut(false);
    });
  };

  const handleAddNewPendantDesign = (): void => {
    // If we're making another map, we want to clear the current one.
    setCartingOut(false);
    Router.push("/pendant", "/pendant", { shallow: true }).then(() => {
      setCartingOut(false);
    });
  };

  const goToCart = async (
    cart: ICart,
    shippingParam: IShippingObject,
    billingParam: IBillingObject,
    newItem?: ICartItem,
  ): Promise<void> => {
    const url = await saveCartInfoToDB(cart, shippingParam, billingParam, undefined, newItem);
    if (url && url !== "") {
      Router.push(url, url, { shallow: true }).then(() => {
        setCartingOut(false);
      });
    }
  };

  const dropAHint = async (
    cart: ICart,
    shippingParam: IShippingObject,
    billingParam: IBillingObject,
    newItem: ICartItem,
    forceSaveDesign?: boolean,
  ): Promise<void> => {
    // If we're editing a saved design, we want to edit order, not make a new one. This has the nice side effect of dealing with browser
    // back button actions if user goes back from the checkout page since the orderId will exist at that point.
    if (orderId && forceSaveDesign !== true) {
      return orderApi
        .editOrder(orderId, cart, shippingParam, billingParam, shouldUseSameAddress, giftNote)
        .then(() => {
          const url = `/cart?customerId=${customerId}&orderId=${orderId}`;
          Router.push(url, url, { shallow: true }).then(() => {
            setCartingOut(false);
          });
        })
        .catch((err) => {
          console.error(err);
          // alert("Something went wrong when trying to edit your order.");
        });
    }

    let hostname: string | undefined;
    if (window && window.location && window.location.hostname && window.location.hostname !== "") {
      hostname = window.location.hostname;
    }

    return orderApi
      .dropAHint(dropAHintFormData, cart, canMarket, shippingParam, billingParam, hostname)
      .then((savedDesignData) => {
        const {
          customerId: savedCustomerId,
          stripeEurCustomerId: savedStripeEurCustomerId,
          stripeUsdCustomerId: savedStripeUsdCustomerId,
          orderId: savedOrderId,
          friendlyOrderId: savedFriendlyOrderId,
          cart: updatedCart,
        }: {
          customerId: string;
          stripeEurCustomerId: string;
          stripeUsdCustomerId: string;
          orderId: string;
          friendlyOrderId: string;
          cart: ICart;
        } = savedDesignData;

        setCustomerId(savedCustomerId);
        setStripeEurCustomerId(savedStripeEurCustomerId);
        setStripeUsdCustomerId(savedStripeUsdCustomerId);
        setOrderId(savedOrderId);
        setFriendlyOrderId(savedFriendlyOrderId);

        // Log Save Design to GA4
        ga4Analytics.logEventDesignDropAHint(savedCustomerId);

        // Log new user
        ga4Analytics.logUserRegistration(savedCustomerId, ACTIVE_SITE);

        // Log Add to Cart
        let addedItem = { ...newItem };
        if (addedItem.id && addedItem.id !== null && updatedCart && updatedCart.items && updatedCart.items.length > 0) {
          const matches = updatedCart.items.filter((x) => x.id === addedItem.id);
          if (matches && matches.length > 0) {
            addedItem = { ...matches[0] };
          }
        }

        let trackCurrency: string | null = null;
        let trackPrice: number | null = null;
        if (addedItem && addedItem.price && addedItem.price.currency && addedItem.price.productPrice) {
          trackCurrency = addedItem.price.currency;
          trackPrice = addedItem.price.productPrice;
        } else if (cart && cart.price && cart.price.currency && cart.price.productPrice) {
          // extimate price if missing:
          trackCurrency = cart.price.currency;
          trackPrice = cart.price.productPrice / Math.max(1, cart.items.length);
        }

        if (trackPrice && trackPrice > 0) {
          trackPrice /= 100;
        }

        // Log Add to Cart:
        ga4Analytics.addProductToCart(savedCustomerId, trackCurrency || "", trackPrice, { ...addedItem });

        // const url = `/cart?customerId=${savedCustomerId}&orderId=${savedOrderId}`;
        // Router.push(url, url, { shallow: true }).then(() => {
        //   setCartingOut(false);
        // });
        // // Router.push("/checkout");

        // Hint Done
        setDropAHintDone(true);
        setCartingOut(false);
      })
      .catch((err) => {
        console.error(err);
        // alert("Something went wrong when trying to create your order.");

        // Hint Donw
        setDropAHintDone(true);
        setCartingOut(false);
      });
  };

  const goToCartAndSaveDesign = async (
    cart: ICart,
    shippingParam: IShippingObject,
    billingParam: IBillingObject,
    newItem: ICartItem,
    forceSaveDesign?: boolean,
  ): Promise<void> => {
    // If we're editing a saved design, we want to edit order, not make a new one. This has the nice side effect of dealing with browser
    // back button actions if user goes back from the checkout page since the orderId will exist at that point.
    if (orderId && forceSaveDesign !== true) {
      return orderApi
        .editOrder(orderId, cart, shippingParam, billingParam, shouldUseSameAddress, giftNote)
        .then(() => {
          const url = `/cart?customerId=${customerId}&orderId=${orderId}`;
          Router.push(url, url, { shallow: true }).then(() => {
            setCartingOut(false);

            // ScrollTo (Cart)
            try {
              window.scroll({ top: 0, left: 0 });
            } catch (error) {
              // just a fallback for older browsers
              window.scrollTo(0, 0);
            }
          });
        })
        .catch((err) => {
          console.error(err);
          // alert("Something went wrong when trying to edit your order.");
        });
    }

    let hostname: string | undefined;
    if (window && window.location && window.location.hostname && window.location.hostname !== "") {
      hostname = window.location.hostname;
    }

    return orderApi
      .saveDesign(email, cart, canMarket, "", "", shippingParam, billingParam, hostname)
      .then((savedDesignData) => {
        const {
          customerId: savedCustomerId,
          stripeEurCustomerId: savedStripeEurCustomerId,
          stripeUsdCustomerId: savedStripeUsdCustomerId,
          orderId: savedOrderId,
          friendlyOrderId: savedFriendlyOrderId,
          cart: updatedCart,
        }: {
          customerId: string;
          stripeEurCustomerId: string;
          stripeUsdCustomerId: string;
          orderId: string;
          friendlyOrderId: string;
          cart: ICart;
        } = savedDesignData;

        setCustomerId(savedCustomerId);
        setStripeEurCustomerId(savedStripeEurCustomerId);
        setStripeUsdCustomerId(savedStripeUsdCustomerId);
        setOrderId(savedOrderId);
        setFriendlyOrderId(savedFriendlyOrderId);

        // Log Save Design to GA4
        ga4Analytics.logEventDesignSaveDesign(savedCustomerId);

        // Log new user
        ga4Analytics.logUserRegistration(savedCustomerId, ACTIVE_SITE);

        // Log Add to Cart
        let addedItem = { ...newItem };
        if (addedItem.id && addedItem.id !== null && updatedCart && updatedCart.items && updatedCart.items.length > 0) {
          const matches = updatedCart.items.filter((x) => x.id === addedItem.id);
          if (matches && matches.length > 0) {
            addedItem = { ...matches[0] };
          }
        }

        let trackCurrency: string | null = null;
        let trackPrice: number | null = null;
        if (addedItem && addedItem.price && addedItem.price.currency && addedItem.price.productPrice) {
          trackCurrency = addedItem.price.currency;
          trackPrice = addedItem.price.productPrice;
        } else if (cart && cart.price && cart.price.currency && cart.price.productPrice) {
          // extimate price if missing:
          trackCurrency = cart.price.currency;
          trackPrice = cart.price.productPrice / Math.max(1, cart.items.length);
        }

        if (trackPrice && trackPrice > 0) {
          trackPrice /= 100;
        }

        // Log Add to Cart:
        ga4Analytics.addProductToCart(savedCustomerId, trackCurrency || "", trackPrice, { ...addedItem });

        const url = `/cart?customerId=${savedCustomerId}&orderId=${savedOrderId}`;
        Router.push(url, url, { shallow: true }).then(() => {
          setCartingOut(false);

          // ScrollTo (Cart)
          try {
            window.scroll({ top: 0, left: 0 });
          } catch (error) {
            // just a fallback for older browsers
            window.scrollTo(0, 0);
          }
        });
        // Router.push("/checkout");
      })
      .catch((err) => {
        console.error(err);
        // alert("Something went wrong when trying to create your order.");
      });
  };

  const goToShippingStep = (): Promise<boolean> => {
    return Router.push({ pathname: "/shipping", query: Router.query }, undefined, {
      shallow: true,
    });
  };

  const goToInformationStep = async (cart?: ICart): Promise<void> => {
    try {
      // If we're editing a saved design, we want to edit order, not make a new one. This has
      // the nice side effect of dealing with browser back button actions if user goes back
      // from the checkout page since the orderId will exist at that point.
      if (cart && cart.items && orderId) {
        await orderApi.editOrder(orderId, cart, shipping, billing, shouldUseSameAddress, giftNote);
      }

      Router.push({ pathname: "/information", query: Router.query }, undefined, { shallow: true });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /**
   * As we move between steps, we create or update the cart item
   */
  const createOrUpdateOrder = async (cart: ICart): Promise<any> => {
    // If we're editing a saved design, we want to edit order, not make a new one. This has the
    // nice side effect of dealing with browser back button actions if user goes back from the
    // checkout page since the orderId will exist at that point.
    if (orderId) {
      // If we're editing a saved design, we want to edit order, not make a new one. This has the
      // nice side effect of dealing with browser back button actions if user goes back from the
      // checkout page since the orderId will exist at that point.
      const updatedCustomer = await orderApi.updateCustomer(email, canMarket, customerId, orderId);
      const updatedCustomerId = updatedCustomer.customerId;
      setStripeEurCustomerId(updatedCustomer.stripeEurCustomerId);
      setStripeUsdCustomerId(updatedCustomer.stripeUsdCustomerId);
      setCustomerId(updatedCustomerId);

      // Update order with new info:
      await orderApi.editOrder(orderId, cart, shipping, billing, shouldUseSameAddress, giftNote);

      return { updatedOrderId: orderId, updatedCustomerId };
    }

    let hostname: string | undefined;
    if (window && window.location && window.location.hostname && window.location.hostname !== "") {
      hostname = window.location.hostname;
    }

    const { firstName, lastName } = shipping.address;
    const createOrderResult = await orderApi.createOrder(
      email,
      cart,
      canMarket,
      firstName || "",
      lastName || "",
      giftNote,
      shipping,
      billing,
      shouldUseSameAddress,
      undefined,
      hostname,
    );

    // get shipping times
    const updatedCustomerId = createOrderResult.customerId;
    const updatedOrderId = createOrderResult.orderId;

    setStripeEurCustomerId(createOrderResult.stripeEurCustomerId);
    setStripeUsdCustomerId(createOrderResult.stripeUsdCustomerId);
    setFriendlyOrderId(createOrderResult.friendlyOrderId);
    setIsReturningCustomer(createOrderResult.isReturning);
    setCustomerId(updatedCustomerId);
    setOrderId(updatedOrderId);

    return {
      updatedOrderId,
      updatedCustomerId,
      updatedDiscount: createOrderResult.updatedDiscount,
    };
  };

  // When the customer clicks to go to checkout, we start the process of making a new order.
  const goToShipping = async (cart: ICart, _shipping: IShippingObject, _billing: IBillingObject): Promise<IDiscountObject> => {
    const { updatedOrderId, updatedCustomerId, updatedDiscount } = await createOrUpdateOrder(cart);

    const url = `/shipping?customerId=${updatedCustomerId}&orderId=${updatedOrderId}`;
    await Router.push(url, url, { shallow: true });

    return updatedDiscount;
  };

  /**
   * When we move to the payment page, skipping the (express/standard choice) shipping page, we
   * need a different route to create the order object in the DB that would usually happen on
   * entering the shipping choice page
   */
  const goToPaymentStepSkipShipping = async (cart: ICart): Promise<void> => {
    const { updatedOrderId, updatedCustomerId } = await createOrUpdateOrder(cart);

    const url = `/payment?customerId=${updatedCustomerId}&orderId=${updatedOrderId}`;
    await Router.push(url, url, { shallow: true });
  };

  const goToPaymentStep = async (cart: ICart): Promise<void> => {
    await orderApi.updateCart(orderId, cart, shipping, billing, shouldUseSameAddress);

    const url = `/payment?customerId=${customerId}&orderId=${orderId}`;
    await Router.push(url, url, { shallow: true });
  };

  const goToCartStep = (): void => {
    // unset Stripe Source on thanks page to it's not processing
    setStripeSourceId("");
    setStripeSourceType("");
    //
    Router.push({ pathname: "/cart", query: Router.query }, undefined, {
      shallow: true,
    }).then(() => {
      // ScrollTo (Cart)
      try {
        window.scroll({ top: 0, left: 0 });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    });
  };

  const goToThanksStep = (customerIdParam: string, orderIdParam: string): Promise<boolean> => {
    // unset Stripe Source on thanks page to it's not processing
    setStripeSourceId("");
    setStripeSourceType("");
    //
    const url = `/thanks?customerId=${customerIdParam}&orderId=${orderIdParam}`;
    return Router.push(url, url, { shallow: false });
  };

  const goToPaymentProcessingStep = (customerIdParam: string, orderIdParam: string, sourceIdParam?: string): Promise<boolean> => {
    setStripeSourceId(sourceIdParam || "");
    setStripeSourceType("");

    const url = `/paymentProcessing?customerId=${customerIdParam}&orderId=${orderIdParam}&sourceId=${sourceIdParam}`;
    return Router.push(url, url, { shallow: false });
  };

  const goToPaymentPage = (customerIdParam: string, orderIdParam: string): Promise<boolean> => {
    // unset Stripe Source on payment page we can reprocess
    setStripeSourceId("");
    setStripeSourceType("");
    //
    const url = `/payment?customerId=${customerIdParam}&orderId=${orderIdParam}`;
    return Router.push(url, url, { shallow: false });
  };

  // const goToSaveThanks = async (cart: ICart, shippingParam: IShippingObject, billingParam: IBillingObject): Promise<void> => {
  //   // If we're editing a saved design, we want to edit order, not make a new one. This has the nice side
  //   // effect of dealing with browser back button actions if user goes back from the checkout page since
  //   // the orderId will exist at that point.
  //   if (orderId) {
  //     return orderApi
  //       .editOrder(orderId, cart, shippingParam, billingParam, shouldUseSameAddress, giftNote)
  //       .then(() => {
  //         Router.push("/saveThanks");
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         // alert(
  //         // "Something went wrong when trying to save your design.",
  //         // );
  //       });
  //   }

  //   let hostname: string | undefined;
  //   if (window && window.location && window.location.hostname && window.location.hostname !== "") {
  //     hostname = window.location.hostname;
  //   }

  //   const { firstName, lastName } = shipping.address;
  //   return orderApi
  //     .saveDesign(email, cart, canMarket, firstName || "", lastName || "", shipping, billing, hostname)
  //     .then(
  //       ({
  //         customerId: savedCustomerId,
  //         stripeEurCustomerId: savedStripeEurCustomerId,
  //         stripeUsdCustomerId: savedStripeUsdCustomerId,
  //         orderId: savedOrderId,
  //         friendlyOrderId: savedFriendlyOrderId,
  //       }) => {
  //         setCustomerId(savedCustomerId);
  //         setStripeEurCustomerId(savedStripeEurCustomerId);
  //         setStripeUsdCustomerId(savedStripeUsdCustomerId);
  //         setOrderId(savedOrderId);
  //         setFriendlyOrderId(savedFriendlyOrderId);
  //         Router.push("/saveThanks");
  //       },
  //     )
  //     .catch((err) => {
  //       console.error(err);
  //       // alert("Something went wrong when trying to create your order.");
  //     });
  // };

  const shippingInfo = (
    cart: ICart,
    shippingType: string,
  ): {
    standard: JSX.Element;
    express: JSX.Element;
  } => {
    const orderContainsPrints = cart.items && cart.items.some((x) => !x.isFramed && !x.isCanvas && !x.isDigital && !x.isPendant);
    const orderContainsFrames = cart.items && cart.items.some((x) => x.isFramed);
    const orderContainsCanvas = cart.items && cart.items.some((x) => x.isCanvas);
    const orderContainsOnyxPendant =
      cart.items &&
      cart.items.some((x) => x.isPendant && x.design.customProps && x.design.customProps.pendantMaterial === PendantMaterials.ONYX);
    const orderContainsSilverPendant =
      cart.items &&
      cart.items.some(
        (x) =>
          x.isPendant &&
          x.design.customProps &&
          (!x.design.customProps.pendantMaterial ||
            x.design.customProps.pendantMaterial === "" ||
            x.design.customProps.pendantMaterial === PendantMaterials.SILVER),
      );
    const orderContainsGoldPendant =
      cart.items &&
      cart.items.some((x) => x.isPendant && x.design.customProps && x.design.customProps.pendantMaterial === PendantMaterials.GOLD);

    const digitalCount = cart.items ? cart.items.filter((item) => item.isDigital).length : 0;
    const containsAllDigital = digitalCount === cart.items.length;

    // defaults
    let standardEarliest = ``;
    let standardLatest = ``;
    let expressEarliest = ``;
    let expressLatest = ``;

    let standardString = `Standard shipping - Tracked shipping.`;
    let expressString = `Express shipping - Tracked shipping.`;
    if (deliveryTimeRanges && deliveryTimeRanges.businessDays) {
      if (!containsAllDigital) {
        const standardTimes = mergeOrderTimings(
          deliveryTimeRanges.businessDays,
          false,
          orderContainsPrints,
          orderContainsFrames,
          orderContainsCanvas,
          orderContainsOnyxPendant,
          orderContainsSilverPendant,
          orderContainsGoldPendant,
        );

        // standardEarliest = standardTimes.submit[0] + standardTimes.fulfillment[0] + standardTimes.shipping[0];
        // standardLatest = standardTimes.submit[1] + standardTimes.fulfillment[1] + standardTimes.shipping[1];
        // 2021-09-28: leave submit time out of ETA Calc
        standardEarliest = standardTimes.fulfillment[0] + standardTimes.shipping[0];
        standardLatest = standardTimes.fulfillment[1] + standardTimes.shipping[1];
        const expressTimes = mergeOrderTimings(
          deliveryTimeRanges.businessDays,
          true,
          orderContainsPrints,
          orderContainsFrames,
          orderContainsCanvas,
          orderContainsOnyxPendant,
          orderContainsSilverPendant,
          orderContainsGoldPendant,
        );

        // expressEarliest = expressTimes.submit[0] + expressTimes.fulfillment[0] + expressTimes.shipping[0];
        // expressLatest = expressTimes.submit[1] + expressTimes.fulfillment[1] + expressTimes.shipping[1];
        // 2021-09-28: leave submit time out of ETA Calc
        expressEarliest = expressTimes.fulfillment[0] + expressTimes.shipping[0];
        expressLatest = expressTimes.fulfillment[1] + expressTimes.shipping[1];

        standardString = `Standard shipping - Tracked shipping, estimated delivery ${standardEarliest} - ${standardLatest} business days from order submit.`;
        expressString = `Express shipping - Tracked shipping, estimated delivery ${expressEarliest} - ${expressLatest} business days from order submit.`;
      }
    }

    const shippingTypeStrings = {
      standard: <>{standardString}</>,
      express: <>{expressString}</>,
    };

    return shippingTypeStrings[shippingType];
  };

  const setOrder = async (orderData: IOrderData): Promise<void> => {
    setEmail(orderData.email);
    setFriendlyOrderId(orderData.friendlyOrderId);
    setCustomerId(orderData.customerId);
    setStripeEurCustomerId(orderData.stripeEurCustomerId);
    setStripeUsdCustomerId(orderData.stripeUsdCustomerId);
    setIsOrderPaid(orderData.isOrderPaid);
    setIsDigitalOnlySite(orderData.isDigital);
    setInitialCountryCode(orderData.countryCode);
    updateCountryCode(orderData.countryCode);
    updateShipping(orderData.shipping);
    setBilling(orderData.billing);
    setGiftNote(orderData.giftNote);
    setPaymentMethodUsed(orderData.paymentMethodUsed);
    setPaymentIntentId(orderData.paymentIntent);
    setPaymentMethodId(orderData.paymentMethod);
    setStatus(orderData.status);
    setOrderId(orderData.orderId);
  };

  const shippingTypeDisabled = (address: IAddress, isExpress: boolean): boolean => {
    // Blacklisted (Brazil, Argentina, Indonesia):
    if (BLACKLISTED_COUNTRIES.indexOf(address.countryCode) >= 0) {
      return true;
    }

    /**
     * Express shipping to Alaska, Guam, Remote Armed Forces, etc is limited
     */
    if (address.countryCode === "US" && isExpress && address.state && Object.keys(usZoneTwoStates).includes(address.state.toLowerCase())) {
      return true;
    }

    // Standard Shipping Enabled
    return false;
  };

  const getDeliveryRangeText = (
    shippingTypeParam: string,
    containsFrames: boolean,
    containsCanvas: boolean,
    containsPendant: boolean,
  ): string | null => {
    if (!deliveryTimeRanges) {
      return "";
    }

    const { businessDays } = deliveryTimeRanges;
    const shippingType = shippingTypeParam || "standard";

    let minDeliveryDateTs =
      businessDays.print[shippingType].submit[0] +
      businessDays.print[shippingType].fulfillment[0] +
      businessDays.print[shippingType].shipping[0];
    let maxDeliveryDateTs =
      businessDays.print[shippingType].submit[1] +
      businessDays.print[shippingType].fulfillment[1] +
      businessDays.print[shippingType].shipping[1];

    if (containsFrames) {
      minDeliveryDateTs = Math.max(
        minDeliveryDateTs,
        businessDays.framed[shippingType].submit[0] +
          businessDays.framed[shippingType].fulfillment[0] +
          businessDays.framed[shippingType].shipping[0],
      );
      maxDeliveryDateTs = Math.max(
        maxDeliveryDateTs,
        businessDays.framed[shippingType].submit[1] +
          businessDays.framed[shippingType].fulfillment[1] +
          businessDays.framed[shippingType].shipping[1],
      );
    }

    if (containsCanvas) {
      minDeliveryDateTs = Math.max(
        minDeliveryDateTs,
        businessDays.canvas[shippingType].submit[0] +
          businessDays.canvas[shippingType].fulfillment[0] +
          businessDays.canvas[shippingType].shipping[0],
      );
      maxDeliveryDateTs = Math.max(
        maxDeliveryDateTs,
        businessDays.canvas[shippingType].submit[1] +
          businessDays.canvas[shippingType].fulfillment[1] +
          businessDays.canvas[shippingType].shipping[1],
      );
    }

    if (containsPendant) {
      minDeliveryDateTs = Math.max(
        minDeliveryDateTs,
        businessDays.pendant[shippingType].submit[0] +
          businessDays.pendant[shippingType].fulfillment[0] +
          businessDays.pendant[shippingType].shipping[0],
      );
      maxDeliveryDateTs = Math.max(
        maxDeliveryDateTs,
        businessDays.pendant[shippingType].submit[1] +
          businessDays.pendant[shippingType].fulfillment[1] +
          businessDays.pendant[shippingType].shipping[1],
      );
    }

    // will deliver by:
    moment.locale(NextI18NextInstance.i18n.language);
    const deliveredEarliest = minDeliveryDateTs;
    const deliveredLatest = maxDeliveryDateTs;

    let deliveryDateRange = ``;
    // if (deliveredEarliest.year() !== deliveredLatest.year()) {
    //   deliveryDateRange = `${deliveredEarliest.format("Do MMMM YYYY")} - ${deliveredLatest.format("Do MMMM YYYY")}`;
    // } else if (deliveredEarliest.month() !== deliveredLatest.month()) {
    //   deliveryDateRange = `${deliveredEarliest.format("Do MMMM")} - ${deliveredLatest.format("Do MMMM YYYY")}`;
    // } else if (deliveredEarliest.date() !== deliveredLatest.date()) {
    //   deliveryDateRange = `${deliveredEarliest.format("Do")} - ${deliveredLatest.format("Do MMMM YYYY")}`;
    // } else {
    //   deliveryDateRange = `${deliveredEarliest.format("MMMM Do YYYY")}`;
    // }

    deliveryDateRange = `${deliveredEarliest} - ${deliveredLatest}`;
    return deliveryDateRange;
  };

  const state = {
    email,
    stripeEurCustomerId,
    stripeUsdCustomerId,
    stripeSourceId,
    stripeSourceType,
    setStripeSourceId,
    setStripeSourceType,
    setCustomerId,
    customerId,
    setOrderId,
    orderId,
    friendlyOrderId,
    paymentIntentId,
    canMarket,
    setEmail,
    handleAddNewPrintDesign,
    handleAddNewPendantDesign,
    goToCart,
    goToCartAndSaveDesign,
    dropAHint,
    goToShipping,
    goToShippingStep,
    goToInformationStep,
    goToPaymentStep,
    goToPaymentStepSkipShipping,
    goToCartStep,
    goToThanksStep,
    goToPaymentProcessingStep,
    goToPaymentPage,
    setCanMarket,
    updatePaymentIntentId,
    updatePaymentMade,
    paymentMade,
    // goToSaveThanks,
    setOrder,
    isOrderPaid,
    setIsOrderPaid,
    isDigitalOnlySite,
    setIsDigitalOnlySite,
    isReturningCustomer,
    paymentMethodId,
    setPaymentMethodId,
    paymentMethodUsed,
    setPaymentMethodUsed,
    giftNote,
    setGiftNote,
    updateShipping,
    shipping,
    updateShippingType,
    updateShippingAddressFormComplete,
    shippingAddressFormComplete,
    alertsData,
    updateAlertsData,
    billing,
    setBilling,
    alertWasShown,
    setAlertWasShown,
    discountUsed,
    setDiscountUsed,
    status,
    setStatus,
    shouldUseSameAddress,
    setShouldUseSameAddress,
    isPaymentLoading,
    setIsPaymentLoading,
    makeOrder,
    clearOrderContext,
    setInitialCountryCode,
    updateCountryCode,
    stripePaymentLoading,
    setStripePaymentLoading,
    updateDeliveryTimeRanges,
    shippingInfo,
    shippingTypeDisabled,
    cartingOut,
    setCartingOut,
    getDeliveryRangeText,
    amazonPayCheckoutSessionId,
    setAmazonPayCheckoutSessionId,
    setCartId,
    cartId,
    saveCartInfoToDB,
    saveCartInfoForShopify,
    setIdempotencyKey,
    idempotencyKey,
    setDropAHintFormData,
    dropAHintFormData,
    setDropAHintDone,
    dropAHintDone,
  };

  useEffect(() => {
    if (shipping.address.countryCode && shipping.address.countryCode !== "") {
      setInitialCountryCode(shipping.address.countryCode);
      return;
    }

    let countryCode = "";
    try {
      if (countryCode === "" && (window as any).location !== undefined && (window as any).location.search !== undefined) {
        const qs = queryString.parse((window as any).location.search);
        if (qs.tnscc && `${qs.tnscc}`.length >= 2) {
          countryCode = `${qs.tnscc}`;
        }
      }

      if (
        countryCode === "" &&
        window &&
        window.localStorage &&
        window.localStorage.getItem("tnsCountryCode") &&
        window.localStorage.getItem("tnsCountryCode") !== ""
      ) {
        countryCode = window.localStorage.getItem("tnsCountryCode") || "";
      }
    } catch {
      // ignore qs parsing issues
    }

    if (countryCode && countryCode !== "") {
      if (!shipping.address.countryCode || shipping.address.countryCode === "") {
        setInitialCountryCode(shipping.address.countryCode || countryCode);
      }
    } else {
      configApi
        .getUserCountryCode()
        .then((cc) => {
          if (cc) {
            setDetectedCountryCode(cc);
          }
          // Don't default to "US"
        })
        .catch(() => {
          // Don't default to "US"
        });
    }
  }, [orderId]);

  useEffect(() => {
    if (detectedCountryCode && detectedCountryCode !== "") {
      setInitialCountryCode(detectedCountryCode);
    }
  }, [detectedCountryCode]);

  useEffect(() => {
    let trackingData: any = {
      customerId,
      orderId,
      email,
    };

    if (billing && billing.address && billing.address.zipCode) {
      const { address } = billing;
      if (shipping.address.firstName && address.firstName !== "") {
        trackingData = { ...trackingData, firstName: address.firstName };
      }

      if (address.lastName && address.lastName !== "") {
        trackingData = { ...trackingData, lastName: address.lastName };
      }

      if (address.city && address.city !== "") {
        trackingData = { ...trackingData, city: address.city };
      }

      if (address.state && address.state !== "") {
        const stateCode = convertStateToStateCode(address.state);
        if (stateCode && stateCode !== "") trackingData = { ...trackingData, stateCode };
      }

      if (address.zipCode && address.zipCode !== "") {
        trackingData = { ...trackingData, zipCode: address.zipCode };
      }

      if (address.countryCode && address.countryCode !== "") {
        trackingData = { ...trackingData, countryCode: address.countryCode };
      }
    } else if (shipping && shipping.address) {
      const { address } = shipping;
      if (shipping.address.firstName && address.firstName !== "") {
        trackingData = { ...trackingData, firstName: address.firstName };
      }

      if (address.lastName && address.lastName !== "") {
        trackingData = { ...trackingData, lastName: address.lastName };
      }

      if (address.city && address.city !== "") {
        trackingData = { ...trackingData, city: address.city };
      }

      if (address.state && address.state !== "") {
        const stateCode = convertStateToStateCode(address.state);
        if (stateCode && stateCode !== "") trackingData = { ...trackingData, stateCode };
      }

      if (address.zipCode && address.zipCode !== "") {
        trackingData = { ...trackingData, zipCode: address.zipCode };
      }

      if (address.countryCode && address.countryCode !== "") {
        trackingData = { ...trackingData, countryCode: address.countryCode };
      }
    }

    setTrackingData(trackingData);
  }, [customerId, orderId, email, shipping.address, billing.address]);

  return <Provider value={state}>{children}</Provider>;
};

const withOrder = (Component) => {
  const WithOrder = (props) => <Consumer>{(orderProps) => <Component {...orderProps} {...props} />}</Consumer>;

  WithOrder.getInitialProps = async (context) => ({
    ...(Component.getInitialProps ? await Component.getInitialProps(context) : {}),
  });

  const displayName = Component.displayName || Component.name || `Unknown`;
  WithOrder.displayName = `WithOrder(${displayName})`;

  return WithOrder;
};

export { OrderProvider, Consumer as OrderConsumer, OrderContext, withOrder };
