/* eslint-disable no-console */
import axios from "axios";
import env from "../config/environment";

const { TNS_PLATFORM_API } = env;

// const itemPricingCache: any[] = [];
const AlertStringCache = {};

/**
 * Get key for caching alert string data
 */
const getAlertStringCacheKey = (
  countryCode: string,
  includesPhoneNumber: boolean,
  includesPOBox: boolean,
  shippingType: string,
  containsPrints: boolean,
  containsFrames: boolean,
  containsCanvas: boolean,
  containsPendant: boolean,
  giftNote?: string,
  shippingAddressState?: string,
  containsFrameCanvasLargerThan18x24?: boolean,
): string => {
  try {
    let key = `${countryCode}`;
    key += `_${includesPhoneNumber ? "p" : "nop"}`;
    key += `_${includesPOBox ? "z" : "noz"}`;
    key += `_${shippingType}`;
    key += `_${containsPrints ? "p" : "nop"}`;
    key += `_${containsFrames ? "f" : "nof"}`;
    key += `_${containsCanvas ? "c" : "noc"}`;
    key += `_${containsPendant ? "j" : "noj"}`;
    key += `_${giftNote ? `${giftNote.length}` : `0`}`;
    key += `_${shippingAddressState || ``}`;
    key += `_${containsFrameCanvasLargerThan18x24 === true}`;

    return key;
  } catch (error) {
    console.error(`Error getting alert string cache key`, error);
  }

  return "";
};

/**
 * Get Alert String:
 */
const getAlertStringFromCache = (
  countryCode: string,
  includesPhoneNumber: boolean,
  includesPOBox: boolean,
  shippingType: string,
  containsPrints: boolean,
  containsFrames: boolean,
  containsCanvas: boolean,
  containsPendant: boolean,
  giftNote?: string,
  shippingAddressState?: string,
  containsFrameCanvasLargerThan18x24?: boolean,
): any => {
  const key = getAlertStringCacheKey(
    countryCode,
    includesPhoneNumber,
    includesPOBox,
    shippingType,
    containsPrints,
    containsFrames,
    containsCanvas,
    containsPendant,
    giftNote,
    shippingAddressState,
    containsFrameCanvasLargerThan18x24,
  );
  if (!key || key === "") {
    return null;
  }

  return AlertStringCache[key];
};

/**
 * Get Alert String:
 */
const storeAlertStringInCache = (
  storeData,
  countryCode: string,
  includesPhoneNumber: boolean,
  includesPOBox: boolean,
  shippingType: string,
  containsPrints: boolean,
  containsFrames: boolean,
  containsCanvas: boolean,
  containsPendant: boolean,
  giftNote?: string,
  shippingAddressState?: string,
  containsFrameCanvasLargerThan18x24?: boolean,
): boolean => {
  const key = getAlertStringCacheKey(
    countryCode,
    includesPhoneNumber,
    includesPOBox,
    shippingType,
    containsPrints,
    containsFrames,
    containsCanvas,
    containsPendant,
    giftNote,
    shippingAddressState,
    containsFrameCanvasLargerThan18x24,
  );
  if (!key || key === "") {
    return false;
  }

  AlertStringCache[key] = storeData;
  return true;
};

const getAlertStringsFromApi = async (
  countryCode: string,
  includesPhoneNumber: boolean,
  includesPOBox: boolean,
  shippingType: string,
  containsPrints: boolean,
  containsFrames: boolean,
  containsCanvas: boolean,
  containsPendant: boolean,
  giftNote?: string,
  shippingAddressState?: string,
  containsFrameCanvasLargerThan18x24?: boolean,
): Promise<any> => {
  const d = {
    countryCode,
    includesPhoneNumber,
    includesPOBox,
    shippingType,
    containsPrints,
    containsFrames,
    containsCanvas,
    containsPendant,
    giftNote: giftNote || null,
    shippingAddressState: shippingAddressState || null,
    containsFrameCanvasLargerThan18x24,
  };

  try {
    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/orders/getAlertsForOrder`, d);
    return data;
  } catch (err) {
    console.error(err.response.data);
    throw err;
  }
};

const getAlertsData = async (
  countryCode: string,
  includesPhoneNumber: boolean,
  includesPOBox: boolean,
  shippingType: string,
  containsPrints: boolean,
  containsFrames: boolean,
  containsCanvas: boolean,
  containsPendant: boolean,
  giftNote?: string,
  shippingAddressState?: string,
  containsFrameCanvasLargerThan18x24?: boolean,
): Promise<any> => {
  try {
    const cachedData = getAlertStringFromCache(
      countryCode,
      includesPhoneNumber,
      includesPOBox,
      shippingType,
      containsPrints,
      containsFrames,
      containsCanvas,
      containsPendant,
      giftNote,
      shippingAddressState,
      containsFrameCanvasLargerThan18x24,
    );
    if (cachedData && cachedData.alert) {
      return cachedData;
    }

    const alertsData = await getAlertStringsFromApi(
      countryCode,
      includesPhoneNumber,
      includesPOBox,
      shippingType,
      containsPrints,
      containsFrames,
      containsCanvas,
      containsPendant,
      giftNote,
      shippingAddressState,
      containsFrameCanvasLargerThan18x24,
    );
    if (!alertsData || alertsData.length <= 0) {
      return null;
    }

    storeAlertStringInCache(
      alertsData,
      countryCode,
      includesPhoneNumber,
      includesPOBox,
      shippingType,
      containsPrints,
      containsFrames,
      containsCanvas,
      containsPendant,
      giftNote,
      shippingAddressState,
      containsFrameCanvasLargerThan18x24,
    );

    return alertsData;
  } catch (error) {
    console.error(`Error getting Alert Data`, error);
  }

  return null;
};

export default {
  getAlertsData,
};
