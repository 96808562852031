// NOTE: the bcp47 and currencyCode determines the formatting shown to users on the frontend and the currency
// code should match the sku pricing for a given country.

export const digitalOnlyCountryCodes = [
  "AR", // Argentina
  "BZ", // Belize
  "BO", // Bolivia (Plurinational State of)
  "BR", // Brazil
  "CL", // Chile
  "CO", // Colombia
  "CR", // Costa Rica
  "CU", // Cuba
  "CW", // Curaçao
  "EC", // Ecuador
  "SV", // El Salvador
  "GF", // French Guiana
  "GD", // Grenada
  "GP", // Guadeloupe
  "GT", // Guatemala
  "GY", // Guyana
  "HT", // Haiti
  "HN", // Honduras
  "IN", // India
  "ID", // Indonesia
  "MX", // Mexico
  "NI", // Nicaragua
  "PA", // Panama
  "PY", // Paraguay
  "PE", // Peru
];

export const countries = {
  AF: {
    name: "Afghanistan",
    bcp47: "ps-AF",
    currencyCode: "usd",
  },
  AX: {
    name: "Aland Islands",
    bcp47: "fi-AX",
    currencyCode: "usd",
  },
  AL: {
    name: "Albania",
    bcp47: "sq-AL",
    currencyCode: "usd",
  },
  DZ: {
    name: "Algeria",
    bcp47: "ar-DZ",
    currencyCode: "usd",
  },
  AS: {
    name: "American Samoa",
    bcp47: "en-AS",
    currencyCode: "usd",
  },
  AD: {
    name: "Andorra",
    bcp47: "ca-AD",
    currencyCode: "usd",
  },
  AO: {
    name: "Angola",
    bcp47: "pt-AO",
    currencyCode: "usd",
  },
  AI: {
    name: "Anguilla",
    bcp47: "en-AI",
    currencyCode: "usd",
  },
  AQ: {
    name: "Antarctica",
    bcp47: "ru-AQ",
    currencyCode: "usd",
  },
  AG: {
    name: "Antigua and Barbuda",
    bcp47: "en-AG",
    currencyCode: "usd",
  },
  AR: {
    name: "Argentina",
    bcp47: "es-AR",
    currencyCode: "usd",
  },
  AM: {
    name: "Armenia",
    bcp47: "hy-AM",
    currencyCode: "usd",
  },
  AW: {
    name: "Aruba",
    bcp47: "nl-AW",
    currencyCode: "usd",
  },
  AU: {
    name: "Australia",
    bcp47: "en-AU",
    currencyCode: "aud",
  },
  AT: {
    name: "Austria",
    bcp47: "de-AT",
    currencyCode: "usd",
  },
  AZ: {
    name: "Azerbaijan",
    bcp47: "az-AZ",
    currencyCode: "usd",
  },
  BS: {
    name: "Bahamas",
    bcp47: "en-BS",
    currencyCode: "usd",
  },
  BH: {
    name: "Bahrain",
    bcp47: "ar-BH",
    currencyCode: "usd",
  },
  BD: {
    name: "Bangladesh",
    bcp47: "bn-BD",
    currencyCode: "usd",
  },
  BB: {
    name: "Barbados",
    bcp47: "en-BB",
    currencyCode: "usd",
  },
  BY: {
    name: "Belarus",
    bcp47: "be-BY",
    currencyCode: "usd",
  },
  BE: {
    name: "Belgium",
    bcp47: "fr-BE",
    currencyCode: "usd",
  },
  BZ: {
    name: "Belize",
    bcp47: "en-BZ",
    currencyCode: "usd",
  },
  BJ: {
    name: "Benin",
    bcp47: "fr-BJ",
    currencyCode: "usd",
  },
  BM: {
    name: "Bermuda",
    bcp47: "en-BM",
    currencyCode: "usd",
  },
  BT: {
    name: "Bhutan",
    bcp47: "bo-BT",
    currencyCode: "usd",
  },
  BO: {
    name: "Bolivia",
    bcp47: "es-BO",
    currencyCode: "usd",
  },
  BA: {
    name: "Bosnia and Herzegovina",
    bcp47: "hr-BA",
    currencyCode: "usd",
  },
  BW: {
    name: "Botswana",
    bcp47: "en-BW",
    currencyCode: "usd",
  },
  BV: {
    name: "Bouvet Island",
    bcp47: "no-BV",
    currencyCode: "usd",
  },
  BR: {
    name: "Brazil",
    bcp47: "pt-BR",
    currencyCode: "usd",
  },
  IO: {
    name: "British Indian Ocean Territory",
    bcp47: "en-IO",
    currencyCode: "usd",
  },
  BN: {
    name: "Brunei Darussalam",
    bcp47: "ms-BN",
    currencyCode: "usd",
  },
  BG: {
    name: "Bulgaria",
    bcp47: "bg-BG",
    currencyCode: "usd",
  },
  BF: {
    name: "Burkina Faso",
    bcp47: "fr-BF",
    currencyCode: "usd",
  },
  BI: {
    name: "Burundi",
    bcp47: "fr-BI",
    currencyCode: "usd",
  },
  KH: {
    name: "Cambodia",
    bcp47: "km-KH",
    currencyCode: "usd",
  },
  CM: {
    name: "Cameroon",
    bcp47: "fr-CM",
    currencyCode: "usd",
  },
  CA: {
    name: "Canada",
    bcp47: "en-CA",
    currencyCode: "usd",
  },
  CV: {
    name: "Cape Verde",
    bcp47: "pt-CV",
    currencyCode: "usd",
  },
  KY: {
    name: "Cayman Islands",
    bcp47: "en-KY",
    currencyCode: "usd",
  },
  CF: {
    name: "Central African Republic",
    bcp47: "fr-CF",
    currencyCode: "usd",
  },
  TD: {
    name: "Chad",
    bcp47: "fr-TD",
    currencyCode: "usd",
  },
  CL: {
    name: "Chile",
    bcp47: "es-CL",
    currencyCode: "usd",
  },
  CN: {
    name: "China",
    bcp47: "zh-CN",
    currencyCode: "usd",
  },
  CX: {
    name: "Christmas Island",
    bcp47: "en-CX",
    currencyCode: "usd",
  },
  CC: {
    name: "Cocos (Keeling) Islands",
    bcp47: "en-CC",
    currencyCode: "usd",
  },
  CO: {
    name: "Colombia",
    bcp47: "es-CO",
    currencyCode: "usd",
  },
  KM: {
    name: "Comoros",
    bcp47: "fr-KM",
    currencyCode: "usd",
  },
  CG: {
    name: "Congo",
    bcp47: "fr-CG",
    currencyCode: "usd",
  },
  CD: {
    name: "Congo the Democratic Republic of the",
    bcp47: "fr-CD",
    currencyCode: "usd",
  },
  CK: {
    name: "Cook Islands",
    bcp47: "en-CK",
    currencyCode: "usd",
  },
  CR: {
    name: "Costa Rica",
    bcp47: "es-CR",
    currencyCode: "usd",
  },
  CI: {
    name: "Cote D'Ivoire",
    bcp47: "fr-CI",
    currencyCode: "usd",
  },
  HR: {
    name: "Croatia",
    bcp47: "hr-HR",
    currencyCode: "usd",
  },
  CU: {
    name: "Cuba",
    bcp47: "es-CU",
    currencyCode: "usd",
  },
  CY: {
    name: "Cyprus",
    bcp47: "el-CY",
    currencyCode: "usd",
  },
  CZ: {
    name: "Czech Republic",
    bcp47: "cs-CZ",
    currencyCode: "usd",
  },
  DK: {
    name: "Denmark",
    bcp47: "da-DK",
    currencyCode: "usd",
  },
  DJ: {
    name: "Djibouti",
    bcp47: "fr-DJ",
    currencyCode: "usd",
  },
  DM: {
    name: "Dominica",
    bcp47: "en-DM",
    currencyCode: "usd",
  },
  DO: {
    name: "Dominican Republic",
    bcp47: "es-DO",
    currencyCode: "usd",
  },
  EC: {
    name: "Ecuador",
    bcp47: "es-EC",
    currencyCode: "usd",
  },
  EG: {
    name: "Egypt",
    bcp47: "ar-EG",
    currencyCode: "usd",
  },
  SV: {
    name: "El Salvador",
    bcp47: "es-SV",
    currencyCode: "usd",
  },
  GQ: {
    name: "Equatorial Guinea",
    bcp47: "es-GQ",
    currencyCode: "usd",
  },
  ER: {
    name: "Eritrea",
    bcp47: "ti-ER",
    currencyCode: "usd",
  },
  EE: {
    name: "Estonia",
    bcp47: "et-EE",
    currencyCode: "usd",
  },
  ET: {
    name: "Ethiopia",
    bcp47: "am-ET",
    currencyCode: "usd",
  },
  FK: {
    name: "Falkland Islands(Malvinas)",
    bcp47: "en-FK",
    currencyCode: "usd",
  },
  FO: {
    name: "Faroe Islands",
    bcp47: "da-FO",
    currencyCode: "usd",
  },
  FJ: {
    name: "Fiji",
    bcp47: "en-FJ",
    currencyCode: "usd",
  },
  FI: {
    name: "Finland",
    bcp47: "fi-FI",
    currencyCode: "usd",
  },
  FR: {
    name: "France",
    bcp47: "fr-FR",
    currencyCode: "usd",
  },
  GF: {
    name: "French Guiana",
    bcp47: "fr-GF",
    currencyCode: "usd",
  },
  PF: {
    name: "French Polynesia",
    bcp47: "fr-PF",
    currencyCode: "usd",
  },
  TF: {
    name: "French Southern Territories",
    bcp47: "fr:TF",
    currencyCode: "usd",
  },
  GA: {
    name: "Gabon",
    bcp47: "fr-GA",
    currencyCode: "usd",
  },
  GM: {
    name: "Gambia",
    bcp47: "en-GM",
    currencyCode: "usd",
  },
  GE: {
    name: "Georgia",
    bcp47: "ka-GE",
    currencyCode: "usd",
  },
  DE: {
    name: "Germany",
    bcp47: "de-DE",
    currencyCode: "usd",
  },
  GH: {
    name: "Ghana",
    bcp47: "en-GH",
    currencyCode: "usd",
  },
  GI: {
    name: "Gibraltar",
    bcp47: "en-GI",
    currencyCode: "usd",
  },
  GR: {
    name: "Greece",
    bcp47: "el-GR",
    currencyCode: "usd",
  },
  GL: {
    name: "Greenland",
    bcp47: "kl-GL",
    currencyCode: "usd",
  },
  GD: {
    name: "Grenada",
    bcp47: "en-GD",
    currencyCode: "usd",
  },
  GP: {
    name: "Guadeloupe",
    bcp47: "fr-GP",
    currencyCode: "usd",
  },
  GU: {
    name: "Guam",
    bcp47: "en-GU",
    currencyCode: "usd",
  },
  GT: {
    name: "Guatemala",
    bcp47: "qut-GT",
    currencyCode: "usd",
  },
  GG: {
    name: "Guernsey",
    bcp47: "en-GG",
    currencyCode: "usd",
  },
  GN: {
    name: "Guinea",
    bcp47: "fr-GN",
    currencyCode: "usd",
  },
  GW: {
    name: "Guinea-Bissau",
    bcp47: "pt-GW",
    currencyCode: "usd",
  },
  GY: {
    name: "Guyana",
    bcp47: "en-GY",
    currencyCode: "usd",
  },
  HT: {
    name: "Haiti",
    bcp47: "fr-HT",
    currencyCode: "usd",
  },
  HM: {
    name: "Heard Island and Mcdonald Islands",
    bcp47: "en-HM",
    currencyCode: "usd",
  },
  VA: {
    name: "Holy See (Vatican City State)",
    bcp47: "it-VA",
    currencyCode: "usd",
  },
  HN: {
    name: "Honduras",
    bcp47: "es-HN",
    currencyCode: "usd",
  },
  HK: {
    name: "Hong Kong",
    bcp47: "zh-HK",
    currencyCode: "usd",
  },
  HU: {
    name: "Hungary",
    bcp47: "hu-HU",
    currencyCode: "usd",
  },
  IS: {
    name: "Iceland",
    bcp47: "is-IS",
    currencyCode: "usd",
  },
  IN: {
    name: "India",
    bcp47: "hi-IN",
    currencyCode: "usd",
  },
  ID: {
    name: "Indonesia",
    bcp47: "id-ID",
    currencyCode: "usd",
  },
  IR: {
    name: "Iran Islamic Republic of",
    bcp47: "fa-IR",
    currencyCode: "usd",
  },
  IQ: {
    name: "Iraq",
    bcp47: "ar-IQ",
    currencyCode: "usd",
  },
  IE: {
    name: "Ireland",
    bcp47: "en-IE",
    currencyCode: "eur",
  },
  IM: {
    name: "Isle of Man",
    bcp47: "en-IM",
    currencyCode: "usd",
  },
  IL: {
    name: "Israel",
    bcp47: "he-IL",
    currencyCode: "usd",
  },
  IT: {
    name: "Italy",
    bcp47: "it-IT",
    currencyCode: "usd",
  },
  JM: {
    name: "Jamaica",
    bcp47: "en-JM",
    currencyCode: "usd",
  },
  JP: {
    name: "Japan",
    bcp47: "ja-JP",
    currencyCode: "usd",
  },
  JE: {
    name: "Jersey",
    bcp47: "en-JE",
    currencyCode: "usd",
  },
  JO: {
    name: "Jordan",
    bcp47: "ar-JO",
    currencyCode: "usd",
  },
  KZ: {
    name: "Kazakhstan",
    bcp47: "kk-KZ",
    currencyCode: "usd",
  },
  KE: {
    name: "Kenya",
    bcp47: "sw-KE",
    currencyCode: "usd",
  },
  KI: {
    name: "Kiribati",
    bcp47: "en-KI",
    currencyCode: "usd",
  },
  KP: {
    name: "Korea Democratic People's Republic of",
    bcp47: "ko-KP",
    currencyCode: "usd",
  },
  KR: {
    name: "Korea Republic of",
    bcp47: "ko-KR",
    currencyCode: "usd",
  },
  XK: {
    name: "Kosovo",
    bcp47: "sq-XK",
    currencyCode: "usd",
  },
  KW: {
    name: "Kuwait",
    bcp47: "ar-KW",
    currencyCode: "usd",
  },
  KG: {
    name: "Kyrgyzstan",
    bcp47: "ky-KG",
    currencyCode: "usd",
  },
  LA: {
    name: "Lao People's Democratic Republic",
    bcp47: "lo-LA",
    currencyCode: "usd",
  },
  LV: {
    name: "Latvia",
    bcp47: "lv-LV",
    currencyCode: "usd",
  },
  LB: {
    name: "Lebanon",
    bcp47: "ar-LB",
    currencyCode: "usd",
  },
  LS: {
    name: "Lesotho",
    bcp47: "en-LS",
    currencyCode: "usd",
  },
  LR: {
    name: "Liberia",
    bcp47: "en-LR",
    currencyCode: "usd",
  },
  LY: {
    name: "Libyan Arab Jamahiriya",
    bcp47: "ar-LY",
    currencyCode: "usd",
  },
  LI: {
    name: "Liechtenstein",
    bcp47: "de-LI",
    currencyCode: "usd",
  },
  LT: {
    name: "Lithuania",
    bcp47: "lt-LT",
    currencyCode: "usd",
  },
  LU: {
    name: "Luxembourg",
    bcp47: "lb-LU",
    currencyCode: "usd",
  },
  MO: {
    name: "Macao",
    bcp47: "ru-MO",
    currencyCode: "usd",
  },
  MK: {
    name: "Macedonia theformer Yugoslav Republic of",
    bcp47: "mk-MK",
    currencyCode: "usd",
  },
  MG: {
    name: "Madagascar",
    bcp47: "plt-MG",
    currencyCode: "usd",
  },
  MW: {
    name: "Malawi",
    bcp47: "en-MW",
    currencyCode: "usd",
  },
  MY: {
    name: "Malaysia",
    bcp47: "ms-MY",
    currencyCode: "usd",
  },
  MV: {
    name: "Maldives",
    bcp47: "dv-MV",
    currencyCode: "usd",
  },
  ML: {
    name: "Mali",
    bcp47: "fr-ML",
    currencyCode: "usd",
  },
  MT: {
    name: "Malta",
    bcp47: "mt-MT",
    currencyCode: "usd",
  },
  MH: {
    name: "Marshall Islands",
    bcp47: "en-MH",
    currencyCode: "usd",
  },
  MQ: {
    name: "Martinique",
    bcp47: "fr-MQ",
    currencyCode: "usd",
  },
  MR: {
    name: "Mauritania",
    bcp47: "ar-MR",
    currencyCode: "usd",
  },
  MU: {
    name: "Mauritius",
    bcp47: "en-MU",
    currencyCode: "usd",
  },
  YT: {
    name: "Mayotte",
    bcp47: "fr-YT",
    currencyCode: "usd",
  },
  MX: {
    name: "Mexico",
    bcp47: "es-MX",
    currencyCode: "usd",
  },
  FM: {
    name: "Micronesia Federated States of",
    bcp47: "en-FM",
    currencyCode: "usd",
  },
  MD: {
    name: "Moldova Republic of",
    bcp47: "ro-MD",
    currencyCode: "usd",
  },
  MC: {
    name: "Monaco",
    bcp47: "fr-MC",
    currencyCode: "usd",
  },
  MN: {
    name: "Mongolia",
    bcp47: "mn-MN",
    currencyCode: "usd",
  },
  ME: {
    name: "Montenegro",
    bcp47: "sr-ME",
    currencyCode: "usd",
  },
  MS: {
    name: "Montserrat",
    bcp47: "en-MS",
    currencyCode: "usd",
  },
  MA: {
    name: "Morocco",
    bcp47: "ar-MA",
    currencyCode: "usd",
  },
  MZ: {
    name: "Mozambique",
    bcp47: "pt-MZ",
    currencyCode: "usd",
  },
  MM: {
    name: "Myanmar",
    bcp47: "my-MM",
    currencyCode: "usd",
  },
  NA: {
    name: "Namibia",
    bcp47: "DE-NA",
    currencyCode: "usd",
  },
  NR: {
    name: "Nauru",
    bcp47: "en-NR",
    currencyCode: "usd",
  },
  NP: {
    name: "Nepal",
    bcp47: "ne-NP",
    currencyCode: "usd",
  },
  NL: {
    name: "Netherlands",
    bcp47: "nl-NL",
    currencyCode: "usd",
  },
  AN: {
    name: "Netherlands Antilles",
    bcp47: "pap-AN",
    currencyCode: "usd",
  },
  NC: {
    name: "New Caledonia",
    bcp47: "fr-NC",
    currencyCode: "usd",
  },
  NZ: {
    name: "New Zealand",
    bcp47: "en-NZ",
    currencyCode: "usd",
  },
  NI: {
    name: "Nicaragua",
    bcp47: "es-NI",
    currencyCode: "usd",
  },
  NE: {
    name: "Niger",
    bcp47: "fr-NE",
    currencyCode: "usd",
  },
  NG: {
    name: "Nigeria",
    bcp47: "en-NG",
    currencyCode: "usd",
  },
  NU: {
    name: "Niue",
    bcp47: "en-NU",
    currencyCode: "usd",
  },
  NF: {
    name: "Norfolk Island",
    bcp47: "en-NF",
    currencyCode: "usd",
  },
  MP: {
    name: "Northern Mariana Islands",
    bcp47: "en-MP",
    currencyCode: "usd",
  },
  NO: {
    name: "Norway",
    bcp47: "no-NO",
    currencyCode: "usd",
  },
  OM: {
    name: "Oman",
    bcp47: "ar-OM",
    currencyCode: "usd",
  },
  PK: {
    name: "Pakistan",
    bcp47: "ur-PK",
    currencyCode: "usd",
  },
  PW: {
    name: "Palau",
    bcp47: "en-PW",
    currencyCode: "usd",
  },
  PS: {
    name: "Palestinian Territory Occupied",
    bcp47: "ar-PS",
    currencyCode: "usd",
  },
  PA: {
    name: "Panama",
    bcp47: "es-PA",
    currencyCode: "usd",
  },
  PG: {
    name: "Papua New Guinea",
    bcp47: "en-PG",
    currencyCode: "usd",
  },
  PY: {
    name: "Paraguay",
    bcp47: "es-PY",
    currencyCode: "usd",
  },
  PE: {
    name: "Peru",
    bcp47: "es-PE",
    currencyCode: "usd",
  },
  PH: {
    name: "Philippines",
    bcp47: "fil-PH",
    currencyCode: "usd",
  },
  PN: {
    name: "Pitcairn",
    bcp47: "en-PN",
    currencyCode: "usd",
  },
  PL: {
    name: "Poland",
    bcp47: "pl-PL",
    currencyCode: "usd",
  },
  PT: {
    name: "Portugal",
    bcp47: "pt-PT",
    currencyCode: "usd",
  },
  PR: {
    name: "Puerto Rico",
    bcp47: "es-PR",
    currencyCode: "usd",
  },
  QA: {
    name: "Qatar",
    bcp47: "ar-QA",
    currencyCode: "usd",
  },
  RE: {
    name: "Reunion",
    bcp47: "fr-RE",
    currencyCode: "usd",
  },
  RO: {
    name: "Romania",
    bcp47: "ro-RO",
    currencyCode: "usd",
  },
  RU: {
    name: "Russia",
    bcp47: "ru-RU",
    currencyCode: "usd",
  },
  RW: {
    name: "Rwanda",
    bcp47: "rw-RW",
    currencyCode: "usd",
  },
  BL: {
    name: "Saint Barthélemy",
    bcp47: "fr-BL",
    currencyCode: "usd",
  },
  SH: {
    name: "Saint Helena",
    bcp47: "en-SH",
    currencyCode: "usd",
  },
  KN: {
    name: "Saint Kitts and Nevis",
    bcp47: "en-KN",
    currencyCode: "usd",
  },
  LC: {
    name: "Saint Lucia",
    bcp47: "en-LC",
    currencyCode: "usd",
  },
  MF: {
    name: "Saint Martin",
    bcp47: "nl-MF",
    currencyCode: "usd",
  },
  PM: {
    name: "Saint Pierre and Miquelon",
    bcp47: "fr-PM",
    currencyCode: "usd",
  },
  VC: {
    name: "Saint Vincent and the Grenadines",
    bcp47: "en-VC",
    currencyCode: "usd",
  },
  WS: {
    name: "Samoa",
    bcp47: "en-WS",
    currencyCode: "usd",
  },
  SM: {
    name: "San Marino",
    bcp47: "it-SM",
    currencyCode: "usd",
  },
  ST: {
    name: "Sao Tome and Principe",
    bcp47: "pt-ST",
    currencyCode: "usd",
  },
  SA: {
    name: "Saudi Arabia",
    bcp47: "ar-SA",
    currencyCode: "usd",
  },
  SN: {
    name: "Senegal",
    bcp47: "fr-SN",
    currencyCode: "usd",
  },
  RS: {
    name: "Serbia",
    bcp47: "sr-RS",
    currencyCode: "usd",
  },
  SC: {
    name: "Seychelles",
    bcp47: "en-SC",
    currencyCode: "usd",
  },
  SL: {
    name: "Sierra Leone",
    bcp47: "en-SL",
    currencyCode: "usd",
  },
  SG: {
    name: "Singapore",
    bcp47: "zh-SG",
    currencyCode: "usd",
  },
  SX: {
    name: "Sint Maarten",
    bcp47: "nl-SX",
    currencyCode: "usd",
  },
  SK: {
    name: "Slovakia",
    bcp47: "sk-SK",
    currencyCode: "usd",
  },
  SI: {
    name: "Slovenia",
    bcp47: "sl-SI",
    currencyCode: "usd",
  },
  SB: {
    name: "Solomon Islands",
    bcp47: "en-SB",
    currencyCode: "usd",
  },
  SO: {
    name: "Somalia",
    bcp47: "so-SO",
    currencyCode: "usd",
  },
  ZA: {
    name: "South Africa",
    bcp47: "en-ZA",
    currencyCode: "usd",
  },
  GS: {
    name: "South Georgia and the South Sandwich Islands",
    bcp47: "en-GS",
    currencyCode: "usd",
  },
  ES: {
    name: "Spain",
    bcp47: "es-ES",
    currencyCode: "usd",
  },
  LK: {
    name: "Sri Lanka",
    bcp47: "si-LK",
    currencyCode: "usd",
  },
  SD: {
    name: "Sudan",
    bcp47: "ar-SD",
    currencyCode: "usd",
  },
  SR: {
    name: "Suriname",
    bcp47: "nl-SR",
    currencyCode: "usd",
  },
  SJ: {
    name: "Svalbard and Janmayen",
    bcp47: "no-SJ",
    currencyCode: "usd",
  },
  SZ: {
    name: "Swaziland",
    bcp47: "en-SZ",
    currencyCode: "usd",
  },
  SE: {
    name: "Sweden",
    bcp47: "sv-SE",
    currencyCode: "usd",
  },
  CH: {
    name: "Switzerland",
    bcp47: "de-CH",
    currencyCode: "usd",
  },
  SY: {
    name: "Syrian Arab Republic",
    bcp47: "ar-SY",
    currencyCode: "usd",
  },
  TW: {
    name: "Taiwan",
    bcp47: "zh-TW",
    currencyCode: "usd",
  },
  TJ: {
    name: "Tajikistan",
    bcp47: "tg-TJ",
    currencyCode: "usd",
  },
  TZ: {
    name: "Tanzania United Republic of",
    bcp47: "en-TZ",
    currencyCode: "usd",
  },
  TH: {
    name: "Thailand",
    bcp47: "th-TH",
    currencyCode: "usd",
  },
  TL: {
    name: "Timor-Leste",
    bcp47: "pt-TL",
    currencyCode: "usd",
  },
  TG: {
    name: "Togo",
    bcp47: "fr-TG",
    currencyCode: "usd",
  },
  TK: {
    name: "Tokelau",
    bcp47: "en-TK",
    currencyCode: "usd",
  },
  TO: {
    name: "Tonga",
    bcp47: "en-TO",
    currencyCode: "usd",
  },
  TT: {
    name: "Trinidad and Tobago",
    bcp47: "en-TT",
    currencyCode: "usd",
  },
  TN: {
    name: "Tunisia",
    bcp47: "ar-TN",
    currencyCode: "usd",
  },
  TR: {
    name: "Turkey",
    bcp47: "tr-TR",
    currencyCode: "usd",
  },
  TM: {
    name: "Turkmenistan",
    bcp47: "tk-TM",
    currencyCode: "usd",
  },
  TC: {
    name: "Turks and Caicos Islands",
    bcp47: "en-TC",
    currencyCode: "usd",
  },
  TV: {
    name: "Tuvalu",
    bcp47: "en-TV",
    currencyCode: "usd",
  },
  UG: {
    name: "Uganda",
    bcp47: "en-UG",
    currencyCode: "usd",
  },
  UA: {
    name: "Ukraine",
    bcp47: "uk-UA",
    currencyCode: "usd",
  },
  AE: {
    name: "United Arab Emirates",
    bcp47: "ar-AE",
    currencyCode: "usd",
  },
  GB: {
    name: "United Kingdom",
    bcp47: "en-GB",
    currencyCode: "gbp",
  },
  US: {
    name: "United States",
    bcp47: "en-US",
    currencyCode: "usd",
  },
  UM: {
    name: "United States Minor Outlying Islands",
    bcp47: "en-UM",
    currencyCode: "usd",
  },
  UY: {
    name: "Uruguay",
    bcp47: "es-UY",
    currencyCode: "usd",
  },
  UZ: {
    name: "Uzbekistan",
    bcp47: "uz-UZ",
    currencyCode: "usd",
  },
  VU: {
    name: "Vanuatu",
    bcp47: "fr-VU",
    currencyCode: "usd",
  },
  VE: {
    name: "Venezuela",
    bcp47: "es-VE",
    currencyCode: "usd",
  },
  VN: {
    name: "Vietnam",
    bcp47: "vi-VN",
    currencyCode: "usd",
  },
  VG: {
    name: "Virgin Islands British",
    bcp47: "en-VG",
    currencyCode: "usd",
  },
  VI: {
    name: "Virgin Islands U.S.",
    bcp47: "en-VI",
    currencyCode: "usd",
  },
  WF: {
    name: "Wallis and Futuna",
    bcp47: "fr-WF",
    currencyCode: "usd",
  },
  EH: {
    name: "Western Sahara",
    bcp47: "ar-EH",
    currencyCode: "usd",
  },
  YE: {
    name: "Yemen",
    bcp47: "ar-YE",
    currencyCode: "usd",
  },
  ZM: {
    name: "Zambia",
    bcp47: "en-ZM",
    currencyCode: "usd",
  },
  ZW: {
    name: "Zimbabwe",
    bcp47: "en-ZW",
    currencyCode: "usd",
  },
};
