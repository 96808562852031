// export const facebookGroupId = "NSSTARMAP";
// export const facebookGroupTitle = "Custom Star Map";

export const snapchatContentCategory = "Home & Garden > Decor > Artwork > Posters, Prints, & Visual Artwork";
export const snapchatCatalog = [
  {
    id: "NSPENDANTV1",
    title: "Pendant",
  },
  {
    id: "NSAQCL12x16",
    title: 'Custom Star Map - Aquamarine - 16" x 12"',
    themeId: "aquamarine",
    sizeId: "12x16",
    orientation: "landscape",
  },
  {
    id: "NSAQWL12x16",
    title: 'Custom Star Map - Aquamarine / White - 16" x 12"',
    themeId: "aquamarineWhite",
    sizeId: "12x16",
    orientation: "landscape",
  },
  {
    id: "NSBKCL12x16",
    title: 'Custom Star Map - Black - 16" x 12"',
    themeId: "black",
    sizeId: "12x16",
    orientation: "landscape",
  },
  {
    id: "NSBKWL12x16",
    title: 'Custom Star Map - Black / White - 16" x 12"',
    themeId: "blackWhite",
    sizeId: "12x16",
    orientation: "landscape",
  },
  {
    id: "NSGBCL12x16",
    title: 'Custom Star Map - Grey Blue - 16" x 12"',
    themeId: "grayblue",
    sizeId: "12x16",
    orientation: "landscape",
  },
  {
    id: "NSGBWL12x16",
    title: 'Custom Star Map - Grey Blue / White - 16" x 12"',
    themeId: "grayblueWhite",
    sizeId: "12x16",
    orientation: "landscape",
  },
  {
    id: "NSMBCL12x16",
    title: 'Custom Star Map - Midnight Blue - 16" x 12"',
    themeId: "midnightblue",
    sizeId: "12x16",
    orientation: "landscape",
  },
  {
    id: "NSMBWL12x16",
    title: 'Custom Star Map - Midnight Blue / White - 16" x 12"',
    themeId: "midnightblueWhite",
    sizeId: "12x16",
    orientation: "landscape",
  },
  {
    id: "NSAQCL18x24",
    title: 'Custom Star Map - Aquamarine - 24" x 18"',
    themeId: "aquamarine",
    sizeId: "18x24",
    orientation: "landscape",
  },
  {
    id: "NSAQWL18x24",
    title: 'Custom Star Map - Aquamarine / White - 24" x 18"',
    themeId: "aquamarineWhite",
    sizeId: "18x24",
    orientation: "landscape",
  },
  {
    id: "NSBKCL18x24",
    title: 'Custom Star Map - Black - 24" x 18"',
    themeId: "black",
    sizeId: "18x24",
    orientation: "landscape",
  },
  {
    id: "NSBKWL18x24",
    title: 'Custom Star Map - Black / White - 24" x 18"',
    themeId: "blackWhite",
    sizeId: "18x24",
    orientation: "landscape",
  },
  {
    id: "NSGBCL18x24",
    title: 'Custom Star Map - Grey Blue - 24" x 18"',
    themeId: "grayblue",
    sizeId: "18x24",
    orientation: "landscape",
  },
  {
    id: "NSGBWL18x24",
    title: 'Custom Star Map - Grey Blue / White - 24" x 18"',
    themeId: "grayblueWhite",
    sizeId: "18x24",
    orientation: "landscape",
  },
  {
    id: "NSMBCL18x24",
    title: 'Custom Star Map - Midnight Blue - 24" x 18"',
    themeId: "midnightblue",
    sizeId: "18x24",
    orientation: "landscape",
  },
  {
    id: "NSMBWL18x24",
    title: 'Custom Star Map - Midnight Blue / White - 24" x 18"',
    themeId: "midnightblueWhite",
    sizeId: "18x24",
    orientation: "landscape",
  },
  {
    id: "NSAQCL24x32",
    title: 'Custom Star Map - Aquamarine - 32" x 24"',
    themeId: "aquamarine",
    sizeId: "24x32",
    orientation: "landscape",
  },
  {
    id: "NSAQWL24x32",
    title: 'Custom Star Map - Aquamarine / White - 32" x 24"',
    themeId: "aquamarineWhite",
    sizeId: "24x32",
    orientation: "landscape",
  },
  {
    id: "NSBKCL24x32",
    title: 'Custom Star Map - Black - 32" x 24"',
    themeId: "black",
    sizeId: "24x32",
    orientation: "landscape",
  },
  {
    id: "NSBKWL24x32",
    title: 'Custom Star Map - Black / White - 32" x 24"',
    themeId: "blackWhite",
    sizeId: "24x32",
    orientation: "landscape",
  },
  {
    id: "NSGBCL24x32",
    title: 'Custom Star Map - Grey Blue - 32" x 24"',
    themeId: "grayblue",
    sizeId: "24x32",
    orientation: "landscape",
  },
  {
    id: "NSGBWL24x32",
    title: 'Custom Star Map - Grey Blue / White - 32" x 24"',
    themeId: "grayblueWhite",
    sizeId: "24x32",
    orientation: "landscape",
  },
  {
    id: "NSMBCL24x32",
    title: 'Custom Star Map - Midnight Blue - 32" x 24"',
    themeId: "midnightblue",
    sizeId: "24x32",
    orientation: "landscape",
  },
  {
    id: "NSMBWL24x32",
    title: 'Custom Star Map - Midnight Blue / White - 32" x 24"',
    themeId: "midnightblueWhite",
    sizeId: "24x32",
    orientation: "landscape",
  },
  {
    id: "NSAQCP12x16",
    title: 'Custom Star Map - Aquamarine - 12" x 16"',
    themeId: "aquamarine",
    sizeId: "12x16",
    orientation: "portrait",
  },
  {
    id: "NSAQWP12x16",
    title: 'Custom Star Map - Aquamarine / White - 12" x 16"',
    themeId: "aquamarineWhite",
    sizeId: "12x16",
    orientation: "portrait",
  },
  {
    id: "NSBKCP12x16",
    title: 'Custom Star Map - Black - 12" x 16"',
    themeId: "black",
    sizeId: "12x16",
    orientation: "portrait",
  },
  {
    id: "NSBKWP12x16",
    title: 'Custom Star Map - Black / White - 12" x 16"',
    themeId: "blackWhite",
    sizeId: "12x16",
    orientation: "portrait",
  },
  {
    id: "NSGBCP12x16",
    title: 'Custom Star Map - Grey Blue - 12" x 16"',
    themeId: "grayblue",
    sizeId: "12x16",
    orientation: "portrait",
  },
  {
    id: "NSGBWP12x16",
    title: 'Custom Star Map - Grey Blue / White - 12" x 16"',
    themeId: "grayblueWhite",
    sizeId: "12x16",
    orientation: "portrait",
  },
  {
    id: "NSMBCP12x16",
    title: 'Custom Star Map - Midnight Blue - 12" x 16"',
    themeId: "midnightblue",
    sizeId: "12x16",
    orientation: "portrait",
  },
  {
    id: "NSMBWP12x16",
    title: 'Custom Star Map - Midnight Blue / White - 12" x 16"',
    themeId: "midnightblueWhite",
    sizeId: "12x16",
    orientation: "portrait",
  },
  {
    id: "NSAQCP18x24",
    title: 'Custom Star Map - Aquamarine - 18" x 24"',
    themeId: "aquamarine",
    sizeId: "18x24",
    orientation: "portrait",
  },
  {
    id: "NSAQWP18x24",
    title: 'Custom Star Map - Aquamarine / White - 18" x 24"',
    themeId: "aquamarineWhite",
    sizeId: "18x24",
    orientation: "portrait",
  },
  {
    id: "NSBKCP18x24",
    title: 'Custom Star Map - Black - 18" x 24"',
    themeId: "black",
    sizeId: "18x24",
    orientation: "portrait",
  },
  {
    id: "NSBKWP18x24",
    title: 'Custom Star Map - Black / White - 18" x 24"',
    themeId: "blackWhite",
    sizeId: "18x24",
    orientation: "portrait",
  },
  {
    id: "NSGBCP18x24",
    title: 'Custom Star Map - Grey Blue - 18" x 24"',
    themeId: "grayblue",
    sizeId: "18x24",
    orientation: "portrait",
  },
  {
    id: "NSGBWP18x24",
    title: 'Custom Star Map - Grey Blue / White - 18" x 24"',
    themeId: "grayblueWhite",
    sizeId: "18x24",
    orientation: "portrait",
  },
  {
    id: "NSMBCP18x24",
    title: 'Custom Star Map - Midnight Blue - 18" x 24"',
    themeId: "midnightblue",
    sizeId: "18x24",
    orientation: "portrait",
  },
  {
    id: "NSMBWP18x24",
    title: 'Custom Star Map - Midnight Blue / White - 18" x 24"',
    themeId: "midnightblueWhite",
    sizeId: "18x24",
    orientation: "portrait",
  },
  {
    id: "NSAQCP24x32",
    title: 'Custom Star Map - Aquamarine - 24" x 32"',
    themeId: "aquamarine",
    sizeId: "24x32",
    orientation: "portrait",
  },
  {
    id: "NSAQWP24x32",
    title: 'Custom Star Map - Aquamarine / White - 24" x 32"',
    themeId: "aquamarineWhite",
    sizeId: "24x32",
    orientation: "portrait",
  },
  {
    id: "NSBKCP24x32",
    title: 'Custom Star Map - Black - 24" x 32"',
    themeId: "black",
    sizeId: "24x32",
    orientation: "portrait",
  },
  {
    id: "NSBKWP24x32",
    title: 'Custom Star Map - Black / White - 24" x 32"',
    themeId: "blackWhite",
    sizeId: "24x32",
    orientation: "portrait",
  },
  {
    id: "NSGBCP24x32",
    title: 'Custom Star Map - Grey Blue - 24" x 32"',
    themeId: "grayblue",
    sizeId: "24x32",
    orientation: "portrait",
  },
  {
    id: "NSGBWP24x32",
    title: 'Custom Star Map - Grey Blue / White - 24" x 32"',
    themeId: "grayblueWhite",
    sizeId: "24x32",
    orientation: "portrait",
  },
  {
    id: "NSMBCP24x32",
    title: 'Custom Star Map - Midnight Blue - 24" x 32"',
    themeId: "midnightblue",
    sizeId: "24x32",
    orientation: "portrait",
  },
  {
    id: "NSMBWP24x32",
    title: 'Custom Star Map - Midnight Blue / White - 24" x 32"',
    themeId: "midnightblueWhite",
    sizeId: "24x32",
    orientation: "portrait",
  },
];
