const NextI18Next = require("next-i18next").default;

const path = require(`path`);

const NextI18NextInstance = new NextI18Next({
  strictMode: false,
  defaultLanguage: "en",
  otherLanguages: ["de", "es", "pt", "fr", "it", "nl", "pl"],
  fallbackLng: "en",
  localePath: path.resolve("./public/static/locales"),
  localeSubpaths: {
    en: "en",
    de: "de",
    es: "es",
    pt: "pt",
    fr: "fr",
    it: "it",
    nl: "nl",
    pl: "pl",
  },
  keySeparator: false,
  ignoreRoutes: ["/.well-known/", "/favicon.ico", "/public/", "/public/", "/api/"],
  detection: {
    lookupQuerystring: "tnsLang",
    lookupLocalStorage: "tnsLang",
    lookupCookie: "tnsLang",
    order: ["path", "querystring", "localStorage", "cookie", "subdomain"],
  },
  useSuspense: true,
  // Cache Translations in Prod - removed temporarily because I had some localhost issues
  // useLocalStorage: process.env.NODE_ENV === `production`,
  // localStorageExpirationTime: 3600000, // in ms, 1 hour
});
NextI18NextInstance.i18n.languages = ["en", "de", "es", "pt", "fr", "it", "nl", "pl"];

const { appWithTranslation, Link, Router, useTranslation, withTranslation, i18n } = NextI18NextInstance;

module.exports = {
  NextI18NextInstance,
  Link,
  Router,
  appWithTranslation,
  withTranslation,
  useTranslation,
  i18n,
};
