import { ICartItem, IShippingObject } from "../../interfaces";
import googleAnalytics from "./googleAnalytics";
import snapchatPixel from "./snapchatPixel";

const init = (): void => {
  if (process.env.BROWSER) {
    // Init Google
    googleAnalytics.initGA();

    // Init Snapchat
    snapchatPixel.init();

    // GA4 Tracking
    // ga4.init()
  }
};

/**
 * Log Page View
 */
const logPageView = (pathName: string): void => {
  // facebookPixel.logPageView(pathName);

  googleAnalytics.logPageView(pathName);

  snapchatPixel.logPageView();

  // Logged by script init
  if (pathName.indexOf("/design") >= 0) {
    snapchatPixel.logDesignPageContentView();

    // Bing
    // bingAdsPixel.logDesignPageContentView();
  }
};

const analyticsAddPurchase = (
  orderId: string,
  total: string,
  currency: string,
  shippingPrice: string,
  coupon: string,
  discountAmount: number,
  items: ICartItem[],
): void => {
  // Send each purchased product from cart to GA
  googleAnalytics.addPurchase(orderId, total, currency, shippingPrice, coupon, discountAmount, items);

  // Snapchat Update
  snapchatPixel.addPurchase(orderId, parseFloat(total), items, currency, coupon, discountAmount);
};

const addPurchaseUpsell = (orderId: string, total: string, currency: string, coupon: string, item: ICartItem): void => {
  googleAnalytics.addPurchaseUpsell(orderId, total, currency, coupon, item);

  // Snapchat Update
  snapchatPixel.addPurchase(orderId, parseFloat(total), [item], currency, coupon, 0);
};

const addProductToCart = (item: ICartItem, shipping: IShippingObject, isFrame: boolean, orderId: string, customerId: string): void => {
  // asdf isFrame should also have isCanvas
  googleAnalytics.addProductToCart(item, shipping, isFrame, orderId);

  snapchatPixel.addProductToCart(item, shipping, orderId);
};

const removeProductFromCart = (item: ICartItem, shipping: IShippingObject, isFrame: boolean, orderId: string): void => {
  googleAnalytics.removeProductFromCart(item, shipping, isFrame, orderId);
};

const checkoutCartStep = (items: ICartItem[], isEditing: boolean, shipping: IShippingObject, orderId: string): void => {
  googleAnalytics.checkoutCartStep(items, isEditing, shipping, orderId);
};

const checkoutInformationStep = (items: ICartItem[], totalPrice: number | null, currency: string): void => {
  googleAnalytics.checkoutInformationStep();
  snapchatPixel.logStartCheckout();
};

const checkoutShippingStep = (shippingType: string): void => {
  googleAnalytics.checkoutShippingStep(shippingType);
};

const analyticsCheckoutPaymentStep = (): void => {
  googleAnalytics.checkoutPaymentStep();
};

const checkoutThanksStep = (): void => {
  googleAnalytics.checkoutThanksStep();
};

const logEvent = (category = "", action = ""): void => {
  googleAnalytics.logEvent(category, action);
};

const setUserEmail = (email: string): void => {
  snapchatPixel.setUserEmail(email);
};

const logPaymentMethod = (action = "", paymentMethod = "", totalValue = 0): void => {
  googleAnalytics.logPaymentMethod(action, paymentMethod, totalValue);
};

// const logException = (
//   description: string = "",
//   fatal: boolean = false,
// ): void => {
//   googleAnalytics.logException(description, fatal);
// };

export default {
  init,
  logPageView,
  logEvent,
  // logException,
  analyticsAddPurchase,
  addPurchaseUpsell,
  addProductToCart,
  removeProductFromCart,
  checkoutCartStep,
  checkoutInformationStep,
  checkoutShippingStep,
  analyticsCheckoutPaymentStep,
  checkoutThanksStep,
  setUserEmail,
  logPaymentMethod,
};
