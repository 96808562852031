/**
 * React Facebook Pixel Module
 *
 * Modified version of react-facebook-pixel from Zain Sajjad <zsajjad93@gmail.com>
 */

//
let initialized = false;
let debug = false;

/**
 * Utilities
 */

/**
 * Warn
 * @param  {...any} args
 */
const warn = (...args) => {
  if (!debug) {
    return;
  }
  // eslint-disable-next-line no-console
  console.info(...["[react-facebook-pixel]"].concat(args));
};

/**
 * Log
 * @param  {...any} args
 */
const log = (...args) => {
  if (!debug) {
    return;
  }
  // eslint-disable-next-line no-console
  console.info(...["[react-facebook-pixel]"].concat(args));
};

const verifyInit = () => {
  if (!initialized) {
    warn("Pixel not initialized before using call Pixel init with required params");
  }
  return initialized;
};

//
const defaultOptions = {
  autoConfig: true,
  debug: false,
};

//
export default {
  load() {
    /* eslint-disable */
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
    /* eslint-enable */
  },

  fbq(...args) {
    if (!verifyInit()) {
      return;
    }

    // @ts-ignore
    window.fbq(...args); // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('${args.slice(0, 2).join("', '")}')`);

      if (args[2]) {
        log("with data", args[2]);
      }
    }
  },

  init(pixelId, advancedMatching = {}, options = defaultOptions) {
    initialized = typeof window !== "undefined" && !!window.fbq;

    this.load();

    if (!pixelId) {
      warn("Please insert pixel id for initializing");
    } else {
      if (options.autoConfig === false) {
        fbq("set", "autoConfig", false, pixelId); // eslint-disable-line no-undef
      }

      this.fbq("init", pixelId, advancedMatching); // eslint-disable-line no-undef

      initialized = true;
      debug = options.debug;
    }
  },

  pageView() {
    if (!verifyInit()) {
      // console.log(`X: GDPR: Page View: !verifyInit`);
      return;
    }

    // console.log(`X: GDPR: FQB: "track", "PageView"`);
    // @ts-ignore
    this.fbq("track", "PageView"); // eslint-disable-line no-undef

    if (debug) {
      log("called fbq('track', 'PageView');");
    }
  },

  track(title, data, eventData) {
    if (!verifyInit()) {
      return;
    }

    this.fbq("track", title, data, eventData); // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('track', '${title}',);`);

      if (data) {
        log("with data", data);
      }
      if (eventData) {
        log("and eventData", eventData);
      }
    }
  },

  trackSingle(pixel, title, data, eventData) {
    if (!verifyInit()) {
      return;
    }

    // @ts-ignore
    this.fbq("trackSingle", pixel, title, data, eventData); // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('trackSingle', '${pixel}', '${title}');`);

      if (data) {
        log("with data", data);
      }
      if (eventData) {
        log("and eventData", eventData);
      }
    }
  },

  trackCustom(event, data, eventData) {
    if (!verifyInit()) {
      return;
    }

    this.fbq("trackCustom", event, data, eventData); // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('trackCustom', '${event}');`);

      if (data) {
        log("with data", data);
      }
      if (eventData) {
        log("and eventData", eventData);
      }
    }
  },

  trackSingleCustom(pixel, event, data, eventData) {
    if (!verifyInit()) {
      return;
    }

    this.fbq("trackSingleCustom", pixel, event, data, eventData); // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('trackSingleCustom', '${pixel}', '${event}');`);

      if (data) {
        log("with data", data);
      }
    }
  },

  grantConsent() {
    if (!verifyInit()) {
      return;
    }

    this.fbq("consent", "grant");
    if (debug) {
      log(`called fbq('consent', 'grant');`);
    }
  },

  revokeConsent() {
    if (!verifyInit()) {
      return;
    }

    this.fbq("consent", "revoke");
    if (debug) {
      log(`called fbq('consent', 'revoke');`);
    }
  },

  /** *
   * Set Geo Processing Options:
   *
   * See: https://developers.facebook.com/docs/marketing-apis/data-processing-options
   */

  // To explicitly *not* enable Limited Data Use (LDU) mode, use:
  deactivateLDU() {
    if (!verifyInit()) {
      return;
    }

    this.fbq("dataProcessingOptions", []);
    if (debug) {
      log(`called fbq('dataProcessingOptions', []) to explicitly disable LDU`);
    }
  },

  // To enable LDU mode using geolocation, use:
  activateLDUWithGeolocation() {
    this.fbq("dataProcessingOptions", ["LDU"], 0, 0);

    if (debug) {
      log(`called fbq("dataProcessingOptions", ["LDU"], 0, 0); to enable LDU with geolocation`);
    }
  },

  // To enable LDU for users and specify user geography (California/enabled):
  activateLDUwithLocationCA() {
    if (!verifyInit()) {
      return;
    }

    // @ts-ignore
    this.fbq("dataProcessingOptions", ["LDU"], 0, 1000);

    if (debug) {
      log(`called fbq("dataProcessingOptions", ["LDU"], 0, 1000); to enable LDU with specified location (California, enabled)`);
    }
  },
};
